import { z } from "zod";

export type OrgRegistrationQuestionId = string;

export enum OrgRegistrationQuestionType {
  explainer = "explainer",
  legalDoc = "legal-doc",
  radio = "radio",
  checkbox = "checkbox",
  freeResponse = "free-response",
  additionalStep = "additionalStep" // These are post checkout steps that must be completed to be considered registered
}

export type OrgRegistrationQuestion__Explainer = z.infer<typeof OrgRegistrationQuestion__ExplainerZod>;
export const OrgRegistrationQuestion__ExplainerZod = z.object({
  id: z.string(),
  orgId: z.string(),
  createdAtMS: z.number(),
  type: z.literal(OrgRegistrationQuestionType.explainer),
  shortTitle: z.string(),
  textMD: z.string()
});

export type OrgRegistrationQuestion__LegalDoc = z.infer<typeof OrgRegistrationQuestion__LegalDocZod>;
export const OrgRegistrationQuestion__LegalDocZod = z.object({
  id: z.string(),
  orgId: z.string(),
  createdAtMS: z.number(),
  type: z.literal(OrgRegistrationQuestionType.legalDoc),
  shortTitle: z.string(),
  textMD: z.string(),
  isRequired: z.boolean(),
  documentName: z.string()
});

export type OrgRegistrationQuestion__Radio = z.infer<typeof OrgRegistrationQuestion__RadioZod>;
export const OrgRegistrationQuestion__RadioZod = z.object({
  id: z.string(),
  orgId: z.string(),
  createdAtMS: z.number(),
  type: z.literal(OrgRegistrationQuestionType.radio),
  shortTitle: z.string(),
  questionMD: z.string(),
  isRequired: z.boolean(),
  options: z.object({ valueMD: z.string() }).array()
});

export type OrgRegistrationQuestion__Checkbox = z.infer<typeof OrgRegistrationQuestion__CheckboxZod>;
export const OrgRegistrationQuestion__CheckboxZod = z.object({
  id: z.string(),
  orgId: z.string(),
  createdAtMS: z.number(),
  type: z.literal(OrgRegistrationQuestionType.checkbox),
  shortTitle: z.string(),
  questionMD: z.string(),
  isRequired: z.boolean(),
  options: z.object({ valueMD: z.string() }).array()
});

export type OrgRegistrationQuestion__FreeResponse = z.infer<typeof OrgRegistrationQuestion__FreeResponseZod>;
export const OrgRegistrationQuestion__FreeResponseZod = z.object({
  id: z.string(),
  orgId: z.string(),
  createdAtMS: z.number(),
  type: z.literal(OrgRegistrationQuestionType.freeResponse),
  shortTitle: z.string(),
  questionMD: z.string(),
  isRequired: z.boolean()
});

export type OrgRegistrationQuestion__AdditionalStep = z.infer<typeof OrgRegistrationQuestion__AdditionalStepZod>;
export const OrgRegistrationQuestion__AdditionalStepZod = z.object({
  id: z.string(),
  orgId: z.string(),
  createdAtMS: z.number(),
  type: z.literal(OrgRegistrationQuestionType.additionalStep),
  shortTitle: z.string(),
  descriptionMD: z.string()
});

export const OrgRegistrationQuestionZod = z.discriminatedUnion("type", [
  OrgRegistrationQuestion__ExplainerZod,
  OrgRegistrationQuestion__LegalDocZod,
  OrgRegistrationQuestion__RadioZod,
  OrgRegistrationQuestion__CheckboxZod,
  OrgRegistrationQuestion__FreeResponseZod,
  OrgRegistrationQuestion__AdditionalStepZod
]);

export type OrgRegistrationQuestion =
  | OrgRegistrationQuestion__Explainer
  | OrgRegistrationQuestion__LegalDoc
  | OrgRegistrationQuestion__Radio
  | OrgRegistrationQuestion__Checkbox
  | OrgRegistrationQuestion__FreeResponse
  | OrgRegistrationQuestion__AdditionalStep;
