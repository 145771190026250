import { AccountId } from "./Account.schema";

export const AccountSecretCollectionName = "accountSecret";
export interface AccountSecret {
  id: AccountId;
  createdAtMS: number;
  paymentMethodsById?: Record<string, PaymentMethod>;
}

export type PaymentMethod = PaymentMethod__BankAccount | PaymentMethod__Card;
export type AccountSecretPaymentMethodId = string;

type Common = {
  id: AccountSecretPaymentMethodId;
  isDefault?: boolean;
  createdAtMS: number;
  updatedAtMS: number;
  ownerName: string;
};

export enum PaymentMethodType {
  card = "card",
  bank = "bank"
}

export type PaymentMethod__Card = {
  type: PaymentMethodType.card;
  brand: string;
  postalCode?: string;
  status: "active" | "expired" | "deactivated";
  expMM: string;
  expYYYY: string;
  cardNumberLast4: string;
  encryptedCardNumber: string;
} & Common;

export type PaymentMethod__BankAccount = {
  type: PaymentMethodType.bank;
  accountNumberLast4: string;
  routingNumber: string;
  encryptedAccountNumber: string;
  bankAccountType: PaymentMethodBankAccountType;
} & Common;

export type PaymentMethodSnapshot =
  | (Omit<PaymentMethod__BankAccount, "encryptedAccountNumber"> & { encryptedAccountNumber: null })
  | (Omit<PaymentMethod__Card, "encryptedCardNumber"> & { encryptedCardNumber: null });

export enum PaymentMethodBankAccountType {
  checking = "checking",
  savings = "savings"
}

export type PaymentMethodOnCreation__Card = Omit<
  PaymentMethod__Card,
  keyof Pick<PaymentMethod__Card, "createdAtMS" | "updatedAtMS" | "encryptedCardNumber" | "id" | "status" | "cardNumberLast4">
> & { cardNumber: string; cvv: string };

export type PaymentMethodOnCreation__BankAccount = Omit<
  PaymentMethod__BankAccount,
  keyof Pick<PaymentMethod__BankAccount, "createdAtMS" | "updatedAtMS" | "encryptedAccountNumber" | "id" | "accountNumberLast4">
> & {
  accountNumber: string;
};

export type PaymentMethodOnCreation = PaymentMethodOnCreation__BankAccount | PaymentMethodOnCreation__Card;
