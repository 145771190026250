import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const SoccerBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.soccer as const
} as const;

const SoccerBaseWithPlayerObj = {
  ...SoccerBaseObj,
  playerId: "" as ScoreKeepingPlayerId
} as const;

export const SKE_soccerObj = {
  SKE_soccerStartPeriod: {
    ...SoccerBaseObj,
    type: "startPeriod" as const,
    starters: {} as undefined | Record<string, true>
  },
  SKE_soccerEndPeriod: {
    ...SoccerBaseObj,
    type: "endPeriod" as const,
    endsGame: true as true | undefined
  },
  SKE_soccerSubstitute: {
    ...SoccerBaseObj,
    type: "substitute" as const,
    subbingIn: {} as Record<string, true>,
    subbingOut: {} as Record<string, true>
  },
  SKE_soccerAssist: {
    ...SoccerBaseWithPlayerObj,
    type: "assist" as const
  },
  SKE_soccerGoal: {
    ...SoccerBaseWithPlayerObj,
    type: "goal" as const
  },
  SKE_soccerMissedShot: {
    ...SoccerBaseWithPlayerObj,
    type: "missedShot" as const
  },
  SKE_soccerYellowCard: {
    ...SoccerBaseWithPlayerObj,
    type: "yellowCard" as const
  },
  SKE_soccerRedCard: {
    ...SoccerBaseWithPlayerObj,
    type: "redCard" as const
  }
} as const;

export type SKE_soccer = Simplify<Values<NullableToOptional<typeof SKE_soccerObj>>>;
