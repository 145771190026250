import { OrgTeamTagId, PlayerBundleId } from "..";
import { OrgId } from "./Org.schema";
export enum OrgCouponType {
  percentOff = "percentOff",
  amountOff = "amountOff"
}
export enum OrgCouponUseType {
  tryouts = "tryouts",
  camps = "camps",
  registration = "registration",
  invoices = "invoices",
  all = "all"
}

export type OrgCouponId = string;
export type OrgCouponBase = {
  id: OrgCouponId;
  code: string;
  orgId: OrgId;
  useLimit?: number;
  numberTimesUsed?: number;
  numberTimesUsedByPlayerBundleId?: Record<PlayerBundleId, number>;
  useType: OrgCouponUseType;
  expirationDateMS?: number;
  playerBundleIds?: Record<PlayerBundleId, true>;
  inviteEmail?: string;
  createdAtMS: number;
  updatedAtMS?: number;
};

// export enum OrgCouponPaymentPlanApplicationMethod {
//   equal = "equal",
//   beginning = "beginning",
//   end = "end"
// }

export type OrgCouponPercentOff = OrgCouponBase & {
  type: OrgCouponType.percentOff;
  percent: number; //value between 0 and 1
};

export type OrgCouponAmountOff = OrgCouponBase & {
  type: OrgCouponType.amountOff;
  amountCents: number;
};

export type OrgCoupon = OrgCouponAmountOff | OrgCouponPercentOff;
