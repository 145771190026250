import { isDevelopmentEnv } from "../internal-utils/isDevelopmentEnv";

export enum GameStatKeys {
  gPlayingTimeMS = "gptms",
  gDidWin = "gdw", // 1 equals true
  gDidLose = "gdl", // 1 equals true
  gDidTie = "gdt" // 1 equals true
}

export enum PlayerStatKeys {
  pPlayingTimeMS = "ppt",
  pPlayingTimePerc = "pptp",
  pCompletedPasses = "cp",
  pTouches = "to",
  pShots = "sh",
  pShotsOnTarget = "sot",
  pGoals = "go",
  pAssists = "as",
  pDribbles = "dr",
  pCrosses = "cr",
  pSuccessfulCrosses = "sc",
  pSuccessfulCrossesPerc = "scp",
  pTurnovers = "tu",
  pRecoveries = "re",
  pFouls = "fo",
  pThrowIns = "ti",
  pSuccessfulThrowIns = "sti",
  pThrowInInfractions = "tii",
  pGoalKicks = "gk",
  pSuccessfulGoalKicks = "gsk",
  pSuccessfulGoalKickPerc = "gksp",
  pKeyPasses = "kp",
  pKeyPassesPerHour = "kph",
  pCompletedPassesPerHour = "cph",
  pSuccessRateWithBallPerc = "spp",
  pPossessionParticipationPerc = "ppp",
  pTouchesPerHour = "toh",
  pShotsPerHour = "shh",
  pShotsOnTargetPerHour = "soth",
  pGoalsPerHour = "goh",
  pAssistsPerHour = "ash",
  pCrossesPerHour = "crh",
  pTurnoversPerHour = "tuh",
  pTurnoverPerc = "tup",
  pQuickSelfTurnoversAndRecoveries = "qstr",
  pYellowCards = "pyc",
  pRedCards = "prc",
  pRecoveriesPerHour = "reh",
  pFoulsPerHour = "foh",
  pSuccessfulThrowInsPerc = "stip",
  pGameWinningGoals = "gwg",
  pPenaltyKicksScored = "pks",
  pPenaltyKicksAttempted = "pka",
  pCornerKicks = "cks",
  pFieldPlayerPlayingTimeMS = "fpm",
  pGoaliePlayingTimeMS = "gptm",
  pShotSavedPerc = "ssp",
  pShotsCaught = "sca",
  pShotsBlockedByGoalieSelf = "sbg",
  pShotsSaved = "ssv",
  pShotsSavedPerHour = "ssvh",
  pGoalsConcededPerHour = "gcph",
  pGoalieShutouts = "gos",
  pGoalsConceded = "goce",
  pOffsides = "of",
  pShotsOnTargetPerc = "stp",
  pShotFinishingPerc = "sftp",
  pShotsDefendedAsGK = "sdgk"
}

export enum TeamStatKeys {
  tPossessions = "tpo",
  tPossessionsWithShotChance = "tspo",
  tTouches = "tt",
  tSuccessfulCornerKicks = "tsck",
  tGoals = "tgo",
  tCompletedPasses = "tcp",
  tTurnovers = "ttu",
  tRecoveries = "tre",
  tCrosses = "tcr",
  tSuccessfulCrosses = "tscr",
  tShots = "tsh",
  tShotsOnTarget = "tsot",
  tFouls = "tfo",
  tOffsides = "tof",
  tCards = "tca",
  tRedCards = "trc",
  tYellowCards = "tyc",
  tHandballs = "thb",
  tFreeKicks = "tfr",
  tThrowIns = "tti",
  tSuccessfulThrowIns = "tsti",
  tThrowInInfractions = "ttii",
  tCornerKicks = "tck",
  tGoalKicks = "tgk",
  tSuccessfulGoalKicks = "tsg",
  tPossessionPerc = "tpp",
  tTurnoverPerc = "ttp",
  tPossessionsWithShotChancePerc = "tspp",
  tSuccessRateWithBallPerc = "tsrb",
  tAvgPassesPerPoss = "tapp",
  tAvgPassesPerPossStartingWithGoalKick = "tappgk",
  tAvgPassesAfterGoalieTouch = "tapgt",
  tLongestPassingChain = "tlpc",
  tAvgPassesOnGoals = "tapg",
  tSuccessfulGoalKickPerc = "tsgp",
  tSuccessfulThrowInPerc = "tstip",
  tSuccessfulCornerKickPerc = "tsckp",
  tSuccessfulCrossPerc = "tscp",
  tShotsOnTargetPerc = "tstp",
  tShotFinishingPerc = "tsftp",
  tGoalieBlocks = "tsbg",
  tGoalieCatches = "tsc",
  tSaves = "tsa",
  tPenaltyKicksScored = "tpks",
  tPenaltyKicksAttempted = "tpka",
  tPkShootoutKicksScored = "tpkshs",
  tGoalieShutouts = "tgos",
  tGoalsConceded = "tgoc",
  tShotDifferential = "tsdpg",
  tGoalDifferential = "tgdpg"
}

export enum PositionNumStatKeys {
  pnRecoveries = "pnrc",
  pnTurnovers = "pntu",
  pnTouches = "pnto"
}

export const SoccerStatKeysObj = {
  ...PlayerStatKeys,
  ...GameStatKeys,
  ...TeamStatKeys,
  ...PositionNumStatKeys
};

export type SoccerStatKeys = TypeOfValues<typeof SoccerStatKeysObj>;

if (isDevelopmentEnv()) {
  //Ensure the short stat ids don't ever conflict
  const uniqueMap: any = {};
  Object.values(SoccerStatKeysObj).forEach(key => {
    if (uniqueMap[key]) {
      throw new Error("Non unique stat id specified for stat: " + key);
    }
    uniqueMap[key] = true;
  });
}

/*** Typescript Helpers **/

type AllValues<T extends Record<PropertyKey, any>> = {
  [P in keyof T]: { value: T[P] };
}[keyof T];

type TypeOfValues<T extends Record<PropertyKey, PropertyKey>> = keyof {
  [P in AllValues<T>["value"]]: true;
};
