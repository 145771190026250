import { Simplify, JsonValue } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const HockeyBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.hockey as const
} as const;

const HockeyBaseWithPlayerObj = {
  ...HockeyBaseObj,
  playerId: "" as ScoreKeepingPlayerId
} as const;

export const SKE_hockeyObj = {
  SKE_hockeyStartPeriod: {
    ...HockeyBaseObj,
    type: "startPeriod" as const
  },
  SKE_hockeyEndPeriod: {
    ...HockeyBaseObj,
    type: "endPeriod" as const,
    endsGame: true as true | undefined
  },
  SKE_hockeyAssist: {
    ...HockeyBaseWithPlayerObj,
    type: "assist" as const
  },
  SKE_hockeyGoal: {
    ...HockeyBaseWithPlayerObj,
    type: "goal" as const
  },
  SKE_hockeyTurnover: {
    ...HockeyBaseWithPlayerObj,
    type: "turnover" as const
  },
  SKE_hockeySteal: {
    ...HockeyBaseWithPlayerObj,
    type: "steal" as const
  },
  SKE_hockeyMissedShot: {
    ...HockeyBaseWithPlayerObj,
    type: "missedShot" as const
  },
  SKE_hockeyPenalty: {
    ...HockeyBaseWithPlayerObj,
    type: "penalty" as const,
    penaltyType: "" as "minor" | "major" | "misconduct" | "match" | "penalty-shot" | "other",
    powerPlayDuration: 0
  },
  SKE_hockeyFoul: {
    ...HockeyBaseWithPlayerObj,
    type: "foul" as const
  }
} as const;

export type SKE_hockey = Values<NullableToOptional<typeof SKE_hockeyObj>>;
