export type LowPriorityNotificationDetailId = string;

export enum LowPriorityNotificationDetailType {
  joinProfileResponse = "joinProfileResponse",
  calendar = "calendar",
  importantEmail = "importantEmail",
  attendanceReminder = "attendanceReminder",
  gameStart = "gameStart",
  scoreUpdate = "scoreUpdate",
  votingClosed = "votingClosed",
  votingReminder = "votingReminder",
  coachShareReminder = "coachShareReminder",
  photoPlea = "photoPlea",
  eventReminder = "eventReminder",
  pollReminder = "pollReminder",
  fundraiserProgress = "fundraiserProgress",
  fundraiserDonation = "fundraiserDonation",
  fundraiserReminder = "fundraiserReminder",
  tryoutOfferResponse = "tryoutOfferResponse",
  newEvaluation = "newEvaluation",
  successfulAutoPayment = "successfulAutoPayment",
  failedAutoPayment = "failedAutoPayment",
  soonToBeChargedPayment = "soonToBeChargedPayment",
  ineligibleNotice = "ineligibleNotice",
  upcomingIneligibilty = "upcomingIneligibilty",
  manualInvoiceAlmostDueReminder = "manualInvoiceAlmostDueReminder",
  manualInvoicePastDue = "manualInvoicePastDue",
  newManualInvoice = "newManualInvoice"
}

export interface LowPriorityNotificationDetail {
  id: LowPriorityNotificationDetailId;
  title: string;
  body: string;
  routerPath: string;
  createdAtMS: number;
  expireAtMS: number;
  type: LowPriorityNotificationDetailType;
}

interface LowPriorityExtraMetaGameStart {
  type: LowPriorityNotificationDetailType.gameStart;
  soccerGameId: string;
}

interface LowPriorityExtraMetaScoreUpdate {
  type: LowPriorityNotificationDetailType.scoreUpdate;
  soccerGameId: string;
}

export type LowPriorityExtraMeta = LowPriorityExtraMetaGameStart | LowPriorityExtraMetaScoreUpdate;
