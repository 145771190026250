import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const LacrosseBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.lacrosse as const
} as const;

const LacrosseBaseWithPlayerObj = {
  ...LacrosseBaseObj,
  playerId: "" as ScoreKeepingPlayerId
} as const;

export const SKE_lacrosseObj = {
  SKE_lacrosseStartPeriod: {
    ...LacrosseBaseObj,
    type: "startPeriod" as const
  },
  SKE_lacrosseEndPeriod: {
    ...LacrosseBaseObj,
    type: "endPeriod" as const,
    endsGame: true as true | undefined
  },
  SKE_lacrosseAssist: {
    ...LacrosseBaseWithPlayerObj,
    type: "assist" as const
  },
  SKE_lacrosseGoal: {
    ...LacrosseBaseWithPlayerObj,
    type: "goal" as const
  },
  SKE_lacrosseTurnover: {
    ...LacrosseBaseWithPlayerObj,
    type: "turnover" as const
  },
  SKE_lacrosseSteal: {
    ...LacrosseBaseWithPlayerObj,
    type: "steal" as const
  },
  SKE_lacrosseMissedShot: {
    ...LacrosseBaseWithPlayerObj,
    type: "missedShot" as const
  },
  SKE_lacrossePenalty: {
    ...LacrosseBaseWithPlayerObj,
    type: "penalty" as const,
    wasPlayerEjected: true,
    duration: 0
  },
  SKE_lacrosseFoul: {
    ...LacrosseBaseWithPlayerObj,
    type: "foul" as const
  }
} as const;

type SKE_LACROSSE_TYPE = NullableToOptional<typeof SKE_lacrosseObj>;

export type SKE_lacrosse = Simplify<Values<SKE_LACROSSE_TYPE>>;
