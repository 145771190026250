export interface OsVersionUpdateSettingVersion {
  version: string;
  startDateMS: number;
}

export interface OsVersionUpdateSettings {
  minRequiredNativeVersion: OsVersionUpdateSettingVersion;
  recommendedNativeVersion: OsVersionUpdateSettingVersion;
  minRequiredPublishedTimestamp: number;
}

export enum FeatureToggles {
  exampleFeature01 = "exampleFeature01",
  disableTrends = "disableTrends",
  enableDeepInviteLinks = "enableDeepInviteLinks",
  enableStopClockMode = "enableStopClockMode",
  teamAlertsChannel = "teamAlertsChannel",
  teamDisableNonStaffReplies = "teamDisableNonStaffReplies",
  blockAndMuteUsers = "blockAndMuteUsers"
}

// /systemStatus
export interface SystemStatus {
  announcementIsVisible: boolean;
  announcement: string;
  isGlobalMaintenanceModeActive: boolean;
  ios: OsVersionUpdateSettings;
  android: OsVersionUpdateSettings;
  paymentAvailabilityDateMS: number;
  globalFeatureToggles?: { [feature in FeatureToggles]?: true };
  // The following takes precedent over the global feature toggle
  // Meant for very small test groups (since all users will have to download this data). If we want to do large groups testing we should look at another solution.
  smallGroupFeatureToggle: { [accountId: string]: { [feature in FeatureToggles]?: true } };
  pricing: {
    individual: {
      numberCents: number;
      displayString: string;
    };
    family: {
      numberCents: number;
      displayString: string;
    };
    team: {
      numberCents: number;
      displayString: string;
      special?: string;
    };
    org: {
      displayString: string;
    };
  };
}

// /simpleHeartBeat/${accountId}/
export interface SimpleHeartBeat {
  d: number;
  dId: string; // deviceId
}

// /deviceRegistration/${accountId}/
export interface DeviceRegistration {
  accountId: string; // redundant since part of the ref path but useful when exporting
  id: string; // reduant since really just the accountId. FirebasePostgresLift assumes it exists so we duplicate it
  // separated into a node so we can just fetch these pieces when actually sending notifications
  derived?: {
    deviceId: string;
    os: "ios" | "android" | "legacy";
    fcmToken: string;
  };
  os: string;
  deviceId: string;
  deviceName: any;
  token?: string; // fcm or apn token depending if android or ios
  notificationPermissionStatus: any;
  iosPermissions?:
    | {
        badge: boolean;
        alert: boolean;
        sound: boolean;
      }
    | {};
  platform?: any;
  publishTime: string;
  deviceYearClass: any;
  git: string;
  nativeAppVersion: any;
  nativeBuildVersion: any;
  otaManifestId?: string;
  deviceLanguage?: any;
  createdAtMS: number;
  updatedAtMS: number;
}

// /keyValueStore/${k}/
export interface KeyValueStore {
  v: any;
  exp: number; // expiration date in ms
}
