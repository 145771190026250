import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const BaseballBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: "" as TEAM_SPORT.baseball | TEAM_SPORT.softball
} as const;

const BaseballBaseWithPlayerObj = {
  ...BaseballBaseObj,
  playerId: "" as ScoreKeepingPlayerId
} as const;

export const SKE_baseballObj = {
  SKE_baseballStartPeriod: {
    ...BaseballBaseObj,
    type: "startPeriod" as const
  },
  SKE_baseballEndPeriod: {
    ...BaseballBaseObj,
    type: "endPeriod" as const,
    endsGame: true as true | undefined
  },
  SKE_baseballRun: {
    ...BaseballBaseWithPlayerObj,
    type: "run" as const,
    points: 1
  }
} as const;

export type SKE_baseball = Values<NullableToOptional<typeof SKE_baseballObj>>;
