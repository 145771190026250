import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const GenericSportBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.other
} as const;

export const SKE_otherObj = {
  SKE_genericSportStartPeriod: {
    ...GenericSportBaseObj,
    type: "startPeriod" as const
  },
  SKE_genericSportScoreUpdate: {
    ...GenericSportBaseObj,
    type: "scoreUpdate" as const,
    message: { ownTeam: 0, opponentTeam: 0 }
  }
} as const;

export type SKE_other = Values<NullableToOptional<typeof SKE_otherObj>>;
