import {
  OrgRegistrationPackageId,
  OrgInvoiceId,
  OrgSeasonId,
  PlayerBundleId,
  TeamId,
  AccountId,
  OrgRegistrationPackage,
  OrgRegistrationAnswer
} from "..";
import { OrgId } from "./Org.schema";
import { OrgRegistrationAnswerId } from "./OrgRegistrationAnswer.schema";

export type OrgRegistrationId = string;

export type OrgRegistration = {
  createdAtMS: number;
  id: OrgRegistrationId;
  playerBundleId: PlayerBundleId;
  orgId: OrgId;
  orgRegistrationPackageId: OrgRegistrationPackageId;
  orgSeasonId: OrgSeasonId;
  orgInvoiceId: OrgInvoiceId;
  answerIds?: Record<OrgRegistrationAnswerId, true>;
  affiliatedOrgTeamsSnapshot: Record<TeamId, { name: string }>;
  orgNameSnapshot: string;
  completedAllAdditionalStepsAtMS: number;
  relevantPackageInfoSnapshot: Pick<OrgRegistrationPackage, "amountCents" | "lineItems"> & {
    teamIdThatDeterminedPackage: TeamId;
  };
};

export enum OrgRegistrationStatus {
  bad = "bad",
  registered = "registered",
  unregistered = "unregistered",
  "no-registration-needed" = "no-registration-needed",
  incomplete = "incomplete",
  "team-not-ready" = "team-not-ready"
}

export enum OrgRegistrationPaymentStatus {
  "not-started" = "not-started",
  "in-progress" = "in-progress",
  "completed" = "completed"
}
export type OrgRegistrationTableData = {
  registrationDateMS?: number;
  status: OrgRegistrationStatus;
  playerBundleId: PlayerBundleId;
  firstName: string;
  lastName: string;
  selfAthleteAccountId?: AccountId;
  packageAmountCents?: number;
  packageName?: string;
  orgRegistration?: OrgRegistration;
};

export type OrgRegistrationSummaryDataBase = {
  teamId: string;
  teamName: string;
  orgSeasonId: string;
  orgId: string;
  packageAmountCents: number;
  lateFeeAmountPaidCents: number;
  defaultPaidAmountCents: number;
  couponAmountCents: number;
  creditAmountCents: number;
  totalDueCents: number;
  remainingDueCents: number;
  totalPaidIncludingCreditsCents: number;
};

export type OrgRegistrationPlayerSummary = OrgRegistrationSummaryDataBase & {
  playerBundleId: PlayerBundleId;
  lineItems: OrgRegistration["relevantPackageInfoSnapshot"]["lineItems"];
  couponCode?: string;
};
export type OrgRegistrationTeamSummary = OrgRegistrationSummaryDataBase & {
  lineItems: OrgRegistration["relevantPackageInfoSnapshot"]["lineItems"][];
  numPlayers: number;
  couponCodesWithUsages: Record<string, number>;
};

export type OrgRegistrationAnswerTableData = {
  playerBundleId: string;
  firstName: string;
  lastName: string;
  answers: OrgRegistrationAnswer[];
  createdAtMS: number;
  teamName: string;
};
