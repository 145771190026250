import { ConversationId } from "../collections/Conversation.schema";
import { CalendarEntryId } from "../collections/CalendarEntry.schema";
import { MessageId } from "../collections/Message.schema";
import { TeamId } from "../collections/Team.schema";
import { AccountId } from "../collections/Account.schema";
import { NotificationSettings, AccountPrivate } from "../collections/AccountPrivate.schema";
import { ActionRequestId } from "../collections/ActionRequest.schema";
import { CONVERSATION_TYPES } from "../constants";
import {
  EvaluationId,
  LowPriorityExtraMeta,
  LowPriorityNotificationDetailType,
  OrgInvoiceId,
  OrgSeasonId,
  PlayerBundleId,
  SoccerGameId
} from "../collections";

export enum NotificationChannels {
  ollieNormal = "ollieNormal",
  olliQuietMode = "olliQuietMode",
  expoLegacy = "ollie-chat-messages"
}

// Work around for "String enum computed values not allowed" issue.
// Occurs when you use ALWAYS_SEND = 'ALWAYS_SEND' as any
// The type definition compiles incorrectly
enum Temp {
  ALWAYS_SEND = "ALWAYS_SEND"
}
export enum PushNotificationSettingToRespect {
  awards = NotificationSettings.awards,
  calendarEvents = NotificationSettings.calendarEvents,
  statsAndGame = NotificationSettings.statsAndGame,
  teamAndOrgChannels = NotificationSettings.teamAndOrgChannels,
  ALWAYS_SEND = Temp.ALWAYS_SEND
}

export enum NotificationType {
  // SPECIAL UNICORNS
  // (show up in areas besides the information tray)
  chatMessage = "cMsg", // g
  liveGameModeChatMessage = "lgmcMsg", // g
  statsReady = "statsReady", //i
  evaluation = "evaluation",
  evaluationCoach = "evaluationCoach",
  // ----
  // NOTIFICATION TRAY
  //  ACTION ITEMS
  //  (show up in tray but float to top)
  actionRequest = "actionRequest",
  //  NON ACTION ITEMS (basically low priority stuff)
  lowPriorityNotification = "lowPri", // i
  //    has ability to specify a path
  //    don't care if they can click to an old path, but we need to handle the UI when they no longer have access to that path
  registration = "registration",
  invoice = "invoice"
}

export const ExcludeNotificationTypesFromBadgeCount = [NotificationType.lowPriorityNotification];

// a messageId, calendarEventId, a generated id, or anything that lets us group bundles that originated from the same event. Plan is to use for auditing
type NotificationTriggerEventId = string;

NotificationSettings.teamAndOrgChannels;

interface PushNotificationDataRoot {
  id: string;
  pushNotificationSettingToRespect: PushNotificationSettingToRespect;
  triggerEventId: NotificationTriggerEventId;
  title: string;
  body: string;
}

interface RealTimeNotificationRoot {
  id: string;
  a?: boolean; // acknowledged
  e: number; // expire date MS
  d: number; //created date MS
}

interface NotificationBundleRoot {
  id: string;
  triggerEventId: NotificationTriggerEventId;
  accountId: AccountId;
}

// -------------------------
// Message
// -------------------------

interface PushNotificationData_Message extends PushNotificationDataRoot {
  type: NotificationType.chatMessage;
  messageId: MessageId;
  conversationId: ConversationId;
  sendingAccountId: AccountId;
}

export interface RealTimeNotification_Message extends RealTimeNotificationRoot {
  t: NotificationType.chatMessage;
  cId: ConversationId;
  sAId: AccountId;
}

export interface NotificationBundle_Message extends NotificationBundleRoot {
  type: NotificationType.chatMessage;
  conversationType: CONVERSATION_TYPES;
  pushNotificationData: PushNotificationData_Message;
  realTimeNotification: RealTimeNotification_Message;
}

// -------------------------
// StatsReady
// -------------------------

interface PushNotificationData_StatsReady extends PushNotificationDataRoot {
  type: NotificationType.statsReady;
  calendarEntryId: CalendarEntryId;
  teamId: TeamId;
}

interface RealTimeNotification_StatsReady extends RealTimeNotificationRoot {
  t: NotificationType.statsReady;
  calId: CalendarEntryId;
  tId: TeamId;
}

export interface NotificationBundle_StatsReady extends NotificationBundleRoot {
  type: NotificationType.statsReady;
  pushNotificationData: PushNotificationData_StatsReady;
  realTimeNotification: RealTimeNotification_StatsReady;
}

// -------------------------
// Evaluation
// -------------------------

interface PushNotificationData_Evaluation extends PushNotificationDataRoot {
  type: NotificationType.evaluation;
  evaluationId: EvaluationId;
  playerBundleId: PlayerBundleId;
}

interface RealTimeNotification_Evaluation extends RealTimeNotificationRoot {
  t: NotificationType.evaluation;
  eId: EvaluationId;
  pbId: PlayerBundleId;
}

export interface NotificationBundle_Evaluation extends NotificationBundleRoot {
  type: NotificationType.evaluation;
  pushNotificationData: PushNotificationData_Evaluation;
  realTimeNotification: RealTimeNotification_Evaluation;
}

// -------------------------
// EvaluationCoach
// -------------------------

interface PushNotificationData_EvaluationCoach extends PushNotificationDataRoot {
  type: NotificationType.evaluationCoach;
  evaluationId: EvaluationId;
  coachAccountId: AccountId;
}

interface RealTimeNotification_EvaluationCoach extends RealTimeNotificationRoot {
  t: NotificationType.evaluationCoach;
  eId: EvaluationId;
  caId: AccountId;
}

export interface NotificationBundle_EvaluationCoach extends NotificationBundleRoot {
  type: NotificationType.evaluationCoach;
  pushNotificationData: PushNotificationData_EvaluationCoach;
  realTimeNotification: RealTimeNotification_EvaluationCoach;
}

// -------------------------
// ActionRequest
// -------------------------

interface PushNotificationData_ActionRequest extends PushNotificationDataRoot {
  type: NotificationType.actionRequest;
  actionRequestId: ActionRequestId;
}

interface RealTimeNotification_ActionRequest extends RealTimeNotificationRoot {
  t: NotificationType.actionRequest;
  rId: ActionRequestId;
}

export interface NotificationBundle_ActionRequest extends NotificationBundleRoot {
  type: NotificationType.actionRequest;
  pushNotificationData: PushNotificationData_ActionRequest;
  realTimeNotification: RealTimeNotification_ActionRequest;
}

// -------------------------
// Low Priority
// -------------------------

export interface PushNotificationData_LowPriority extends PushNotificationDataRoot {
  type: NotificationType.lowPriorityNotification;
  routerPath?: string; // Though we could look this up we want it to feel instance
  lowPriorityNotificationDetailType: LowPriorityNotificationDetailType;
  soccerGameId?: SoccerGameId;
}

export interface RealTimeNotification_LowPriority extends RealTimeNotificationRoot {
  t: NotificationType.lowPriorityNotification;
  lpId: string; // Generic notification id (since we look these up)
}

export interface NotificationBundle_LowPriority extends NotificationBundleRoot {
  type: NotificationType.lowPriorityNotification;
  pushNotificationData?: PushNotificationData_LowPriority;
  realTimeNotification?: RealTimeNotification_LowPriority;
}

// -------------------------
// Registration
// -------------------------
export interface RealTimeNotification_Registration extends RealTimeNotificationRoot {
  t: NotificationType.registration;
  pbId: PlayerBundleId; // playerBundleId
  osId: OrgSeasonId; // orgSeasonId
}

// -------------------------
// Invoice
// -------------------------
export interface RealTimeNotification_Invoice extends RealTimeNotificationRoot {
  t: NotificationType.invoice;
  pbId: PlayerBundleId; // playerBundleId
  oiId: OrgInvoiceId; // orgInvoiceId
}

// -------------------------
// Exports
// -------------------------
export type NotificationBundle =
  | NotificationBundle_Message
  | NotificationBundle_StatsReady
  | NotificationBundle_Evaluation
  | NotificationBundle_EvaluationCoach
  | NotificationBundle_ActionRequest
  | NotificationBundle_LowPriority;

export type RealTimeNotification =
  | RealTimeNotification_Message
  | RealTimeNotification_StatsReady
  | RealTimeNotification_Evaluation
  | RealTimeNotification_EvaluationCoach
  | RealTimeNotification_ActionRequest
  | RealTimeNotification_LowPriority
  | RealTimeNotification_Registration
  | RealTimeNotification_Invoice;

export type PushNotificationData =
  | PushNotificationData_Message
  | PushNotificationData_StatsReady
  | PushNotificationData_Evaluation
  | PushNotificationData_EvaluationCoach
  | PushNotificationData_ActionRequest
  | PushNotificationData_LowPriority;

// This is added to the generic-metric table with ollie-pipe
export interface NotificationEventTriggerResult {
  triggerEventId: NotificationTriggerEventId;
  createdAtMS: number;
  status: "init" | "realtime-sent" | "finished" | "unexpected-error";
  totalPushNotificationsSent: number;
  totalRealTimeNotificationsSent: number;
  totalPushNotificationErrors: number;
  totalUnexpectedErrors: number; // Has the concept of an unexpected error in the sense that we expect at least some errors with push notifications as peoples tokens change and what not
  unexpectedError?: any;
  bundles: {
    [accountId: string]: {
      bundle: NotificationBundle;
      realtimeResult?: { sent: boolean; notes?: string }; // If realtime notification sent
      pushResult?: {
        sent: boolean;
        deviceId?: string;
        tokenShort?: string; // Just first few characters of token for id purposes. Usually a fcm token but could also be a legacy expo token initially
        fcmMsgId?: string; // message id ack fcm got your request
        errorDetails?: any; // error message from fcm or something else
        notes?: string; //
      };
      unexpectedBundleError?: any;
    };
  };
}
