import { OrgId, OrgRegistrationPackageId } from "..";
import { AccountId, AccountInfo } from "./Account.schema";
import { PlayerId, Player } from "./Player.schema";
import { TeamId } from "./Team.schema";

export interface PlayerBundle__Account {
  exists: true;
  type: PlayerBundle__AccountType;
}

export enum PlayerBundle__AccountType {
  guardian = "guardian",
  selfAthlete = "selfAthlete"
}

export type PlayerBundle__LinkedPlayers__item = {
  teamId: TeamId;
  status: "active" | "inactive";
  guestPlayerExirationMS?: number; //This is derived from the linked player object
};
export type PlayerBundle__LinkedPlayers = Record<PlayerId, PlayerBundle__LinkedPlayers__item>;

export type PlayerBundleId = string;

export interface PlayerBundle {
  id: PlayerBundleId;
  createdAtMS: number;
  managingAccounts?: Record<AccountId, PlayerBundle__Account | undefined>;
  virtualAthleteAccount: AccountInfo; //This is derived if need be from the selfAthlete account
  deletedAtMS: number;
  derived: {
    activeLinkedOrgs?: Record<OrgId, true>; //Keep this in sync with linkedPlayers. An org should only be present if there is an active linked player affiliated with the org
    linkedPlayers: PlayerBundle__LinkedPlayers;
  };
  exemptOrgRegistrationPackageIds?: Record<OrgRegistrationPackageId, true>;
}

export interface PrettyPlayerBundle {
  playerBundle: PlayerBundle;
  derived: {
    accountInfo: AccountInfo;
    accountInfoSource: "account" | "playerBundle";
  };
}
