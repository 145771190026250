export type ScoreKeepingEventId = string;

export const ScoreKeepingEventBaseObj = {
  id: "",
  calendarEntryId: "",
  teamId: "",
  recorderAccountId: "",
  createdAtMS: 0
};

export type ScoreKeepingPlayerId = string | "own-team-generic-player" | "opponent-team-generic-player";
