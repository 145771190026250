import { PlayerId } from ".";

export type ChecklistItemType = "individual" | "team";
export type ChecklistItemId = string;

interface ChecklistItemBase {
  id: ChecklistItemId;
  teamId: string;
  createdAtMS: number;
  deletedAtMS: number;
  title: string;
  notes?: string;
}

export interface ChecklistItemTeam extends ChecklistItemBase {
  type: "team";
  statusDetails?: ChecklistItemStatusDetails;
}

export interface ChecklistItemIndividual extends ChecklistItemBase {
  type: "individual";
  playerStatusDetails: Record<PlayerId, ChecklistItemStatusDetails | undefined>;
}

export interface ChecklistItemStatusDetails {
  status?: ChecklistItemStatus;
  notes?: string;
}

export enum ChecklistItemStatus {
  check = "check",
  na = "na"
}

export type ChecklistItem = ChecklistItemIndividual | ChecklistItemTeam;
