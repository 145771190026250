export type GiphyUrls = { preview: string; original: string; height?: number; width?: number };

export type DefaultGiphyType = "good" | "bad" | "all";

// Emotions: Joy, Excitement, Celebration, Relief, Stressed, Mad, Shocked,

export const defaultGoodGiphyUrls: GiphyUrls[] = [
  {
    original:
      "https://media0.giphy.com/media/tgSPq03054DTy/giphy.gif?cid=f5766284d08073c3b1e9efe32222756b1cf8ad285bd81a96&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/tgSPq03054DTy/100.gif?cid=f5766284d08073c3b1e9efe32222756b1cf8ad285bd81a96&rid=100.gif",
    height: 228,
    width: 375
  },
  {
    original:
      "https://media1.giphy.com/media/dYZuqJLDVsWMLWyIxJ/giphy.gif?cid=f576628448c15675196f76f9facd79dc5a1db9d03654475d&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/dYZuqJLDVsWMLWyIxJ/100.gif?cid=f576628448c15675196f76f9facd79dc5a1db9d03654475d&rid=100.gif",
    height: 214,
    width: 342
  },
  {
    original:
      "https://media1.giphy.com/media/nXxOjZrbnbRxS/giphy.gif?cid=f576628448c15675196f76f9facd79dc5a1db9d03654475d&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/nXxOjZrbnbRxS/100.gif?cid=f576628448c15675196f76f9facd79dc5a1db9d03654475d&rid=100.gif",
    height: 200,
    width: 200
  },
  {
    original:
      "https://media2.giphy.com/media/3o6UB3VhArvomJHtdK/giphy.gif?cid=f576628448c15675196f76f9facd79dc5a1db9d03654475d&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/3o6UB3VhArvomJHtdK/100.gif?cid=f576628448c15675196f76f9facd79dc5a1db9d03654475d&rid=100.gif",
    height: 354,
    width: 400
  },
  {
    original:
      "https://media3.giphy.com/media/2n8480RCQ2jBe/giphy.gif?cid=f576628440a48cae1647decba161b368df8101297dd760b4&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/2n8480RCQ2jBe/100.gif?cid=f576628440a48cae1647decba161b368df8101297dd760b4&rid=100.gif",
    height: 181,
    width: 236
  },
  {
    original:
      "https://media1.giphy.com/media/BlVnrxJgTGsUw/giphy.gif?cid=f576628466bca3c294403168071458c437f8bbda775ce194&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/BlVnrxJgTGsUw/100.gif?cid=f576628466bca3c294403168071458c437f8bbda775ce194&rid=100.gif",
    height: 303,
    width: 250
  },
  {
    original:
      "https://media3.giphy.com/media/Is1O1TWV0LEJi/giphy.gif?cid=f57662841fcda2c9e11e10a7c7e4e854221392202749ab3b&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/Is1O1TWV0LEJi/100.gif?cid=f57662841fcda2c9e11e10a7c7e4e854221392202749ab3b&rid=100.gif",
    height: 198,
    width: 350
  },
  {
    original:
      "https://media1.giphy.com/media/KYElw07kzDspaBOwf9/giphy.gif?cid=f57662841fcda2c9e11e10a7c7e4e854221392202749ab3b&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/KYElw07kzDspaBOwf9/100.gif?cid=f57662841fcda2c9e11e10a7c7e4e854221392202749ab3b&rid=100.gif",
    height: 243,
    width: 498
  },
  {
    original:
      "https://media3.giphy.com/media/6nuiJjOOQBBn2/giphy.gif?cid=f57662841fcda2c9e11e10a7c7e4e854221392202749ab3b&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/6nuiJjOOQBBn2/100.gif?cid=f57662841fcda2c9e11e10a7c7e4e854221392202749ab3b&rid=100.gif",
    height: 172,
    width: 300
  },
  {
    original:
      "https://media3.giphy.com/media/lpHPFVpk65qpbH2XY5/giphy.gif?cid=f576628457579dc0e9bb0829b84769616f5c36c4657f9572&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/lpHPFVpk65qpbH2XY5/100.gif?cid=f576628457579dc0e9bb0829b84769616f5c36c4657f9572&rid=100.gif",
    height: 480,
    width: 480
  },
  {
    original:
      "https://media0.giphy.com/media/9MIIjUe8fNZfVRQfNP/giphy.gif?cid=f576628457579dc0e9bb0829b84769616f5c36c4657f9572&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/9MIIjUe8fNZfVRQfNP/100.gif?cid=f576628457579dc0e9bb0829b84769616f5c36c4657f9572&rid=100.gif",
    height: 270,
    width: 480
  },
  {
    original:
      "https://media0.giphy.com/media/mks5DcSGjhQ1a/giphy.gif?cid=f576628413e580ac5d535655e760279e03a3002c83b35aff&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/mks5DcSGjhQ1a/100.gif?cid=f576628413e580ac5d535655e760279e03a3002c83b35aff&rid=100.gif",
    height: 179,
    width: 245
  },
  {
    original:
      "https://media0.giphy.com/media/l41m1mh2nyvm5Lk3u/giphy.gif?cid=f5766284572c994112b7651d4373d2aeaae3f4b98cef92f2&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/l41m1mh2nyvm5Lk3u/100.gif?cid=f5766284572c994112b7651d4373d2aeaae3f4b98cef92f2&rid=100.gif",
    height: 270,
    width: 480
  },
  {
    original:
      "https://media3.giphy.com/media/1Xhy3LOUQN3w22Tq4y/giphy.gif?cid=f5766284572c994112b7651d4373d2aeaae3f4b98cef92f2&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/1Xhy3LOUQN3w22Tq4y/100.gif?cid=f5766284572c994112b7651d4373d2aeaae3f4b98cef92f2&rid=100.gif",
    height: 255,
    width: 480
  },
  {
    original:
      "https://media3.giphy.com/media/SKAQ4kWov6tdC/giphy.gif?cid=f5766284cbda96e2133be4c144ea1fabef906810eef165ae&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/SKAQ4kWov6tdC/100.gif?cid=f5766284cbda96e2133be4c144ea1fabef906810eef165ae&rid=100.gif",
    height: 200,
    width: 245
  },
  {
    original:
      "https://media2.giphy.com/media/xUA7aMiHZrWQ7Q93AA/giphy.gif?cid=f576628469115d6c6615625449ee59f17867c85d3e1d9ef5&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/xUA7aMiHZrWQ7Q93AA/100.gif?cid=f576628469115d6c6615625449ee59f17867c85d3e1d9ef5&rid=100.gif",
    height: 175,
    width: 245
  },
  {
    original:
      "https://media3.giphy.com/media/3oKIPuZUcUO0vzwPEQ/giphy.gif?cid=f5766284cbda96e2133be4c144ea1fabef906810eef165ae&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/3oKIPuZUcUO0vzwPEQ/100.gif?cid=f5766284cbda96e2133be4c144ea1fabef906810eef165ae&rid=100.gif",
    height: 270,
    width: 480
  },
  {
    original:
      "https://media0.giphy.com/media/l4JyLsUXD0p8X5vUI/giphy.gif?cid=f5766284ccc73fab3f9ba30a452d38cb5da049b6bbdd4135&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/l4JyLsUXD0p8X5vUI/100.gif?cid=f5766284ccc73fab3f9ba30a452d38cb5da049b6bbdd4135&rid=100.gif",
    height: 376,
    width: 378
  },
  {
    original:
      "https://media2.giphy.com/media/RMHmOe6wyezrLQtVD5/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/RMHmOe6wyezrLQtVD5/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 480,
    width: 480
  },
  {
    original:
      "https://media1.giphy.com/media/chbjLSlJVE0c01OpOA/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/chbjLSlJVE0c01OpOA/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 480,
    width: 480
  },
  {
    original:
      "https://media3.giphy.com/media/sRHVFZVZlHsOBwYTFn/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/sRHVFZVZlHsOBwYTFn/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 255,
    width: 480
  },
  {
    original:
      "https://media0.giphy.com/media/ORVArZlFq0DSyVJ6C8/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/ORVArZlFq0DSyVJ6C8/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 400,
    width: 400
  },
  {
    original:
      "https://media3.giphy.com/media/JoaDGxRSYftKAwfSlz/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/JoaDGxRSYftKAwfSlz/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 480,
    width: 480
  },
  {
    original:
      "https://media1.giphy.com/media/3o7qDMlVquZI1axqQ8/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/3o7qDMlVquZI1axqQ8/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 446,
    width: 480
  },
  {
    original:
      "https://media3.giphy.com/media/RVOItZlD09Qn6/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/RVOItZlD09Qn6/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 205,
    width: 245
  },
  {
    original:
      "https://media0.giphy.com/media/ddHhhUBn25cuQ/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/ddHhhUBn25cuQ/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 360,
    width: 640
  },
  {
    original:
      "https://media2.giphy.com/media/srg19CG0cKMuI/giphy.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/srg19CG0cKMuI/100.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=100.gif",
    height: 169,
    width: 300
  },
  {
    original:
      "https://media0.giphy.com/media/l0MYJnJQ4EiYLxvQ4/giphy.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/l0MYJnJQ4EiYLxvQ4/100.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=100.gif",
    height: 418,
    width: 480
  },
  {
    original:
      "https://media1.giphy.com/media/fnK0jeA8vIh2QLq3IZ/giphy.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/fnK0jeA8vIh2QLq3IZ/100.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=100.gif",
    height: 450,
    width: 450
  },
  {
    original:
      "https://media3.giphy.com/media/3o72FcJmLzIdYJdmDe/giphy.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/3o72FcJmLzIdYJdmDe/100.gif?cid=f5766284ef54773a92291ff8ec8ec44b88f2bd9f1e7d0888&rid=100.gif",
    height: 281,
    width: 500
  },
  {
    original:
      "https://media2.giphy.com/media/S5yqNNTQlEZfqQ7InC/giphy.gif?cid=f57662841ca2222e2cc77ee6ff782d1f47427a6c268888d5&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/S5yqNNTQlEZfqQ7InC/100.gif?cid=f57662841ca2222e2cc77ee6ff782d1f47427a6c268888d5&rid=100.gif",
    height: 224,
    width: 400
  },
  {
    original:
      "https://media2.giphy.com/media/3o7buiQeyYFamzRoR2/giphy.gif?cid=f5766284dce6e33193363ed7a58620b108cb0b6e18a9e467&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/3o7buiQeyYFamzRoR2/100.gif?cid=f5766284dce6e33193363ed7a58620b108cb0b6e18a9e467&rid=100.gif",
    height: 294,
    width: 396
  },
  {
    original:
      "https://media3.giphy.com/media/31lPv5L3aIvTi/giphy.gif?cid=f5766284dce6e33193363ed7a58620b108cb0b6e18a9e467&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/31lPv5L3aIvTi/100.gif?cid=f5766284dce6e33193363ed7a58620b108cb0b6e18a9e467&rid=100.gif",
    height: 314,
    width: 414
  },
  {
    original:
      "https://media0.giphy.com/media/l0MYt5jPR6QX5pnqM/giphy.gif?cid=f57662842e8b0b0f0dfce6b26dfc661f2483a61918ce4177&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/l0MYt5jPR6QX5pnqM/100.gif?cid=f57662842e8b0b0f0dfce6b26dfc661f2483a61918ce4177&rid=100.gif",
    height: 208,
    width: 370
  },
  {
    original:
      "https://media0.giphy.com/media/XR9Dp54ZC4dji/giphy.gif?cid=f5766284cbf8a94dfcf00f3e5cb57b484f507b9aba1bcf66&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/XR9Dp54ZC4dji/100.gif?cid=f5766284cbf8a94dfcf00f3e5cb57b484f507b9aba1bcf66&rid=100.gif",
    height: 300,
    width: 500
  },
  {
    original:
      "https://media0.giphy.com/media/xUA7b2uqotaDunMxaw/giphy.gif?cid=f5766284ccc73fab3f9ba30a452d38cb5da049b6bbdd4135&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/xUA7b2uqotaDunMxaw/100.gif?cid=f5766284ccc73fab3f9ba30a452d38cb5da049b6bbdd4135&rid=100.gif",
    height: 270,
    width: 480
  },
  {
    original:
      "https://media3.giphy.com/media/120jXUxrHF5QJ2/giphy.gif?cid=f5766284b5dcf4e7ac1d666df006d145b594eab9856b3115&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/120jXUxrHF5QJ2/100.gif?cid=f5766284b5dcf4e7ac1d666df006d145b594eab9856b3115&rid=100.gif",
    height: 223,
    width: 400
  },
  {
    original:
      "https://media0.giphy.com/media/3oriNNpjz6oKniJoyc/giphy.gif?cid=f5766284812748fd2382a62b3c6258e2054cc60fb389e36b&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/3oriNNpjz6oKniJoyc/100.gif?cid=f5766284812748fd2382a62b3c6258e2054cc60fb389e36b&rid=100.gif",
    height: 279,
    width: 480
  },
  {
    original:
      "https://media3.giphy.com/media/lnlAifQdenMxW/giphy.gif?cid=f5766284694c7dbfaee787eab7f87c0b20915122fb0e5752&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/lnlAifQdenMxW/100.gif?cid=f5766284694c7dbfaee787eab7f87c0b20915122fb0e5752&rid=100.gif",
    height: 179,
    width: 314
  },
  {
    original:
      "https://media0.giphy.com/media/LpQrsRA3zOuJNk7KYt/giphy.gif?cid=f5766284ef08d5d537908e7c3095b3a4acfd5b42b4627323&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/LpQrsRA3zOuJNk7KYt/100.gif?cid=f5766284ef08d5d537908e7c3095b3a4acfd5b42b4627323&rid=100.gif",
    height: 270,
    width: 480
  },
  {
    original:
      "https://media0.giphy.com/media/3o6Zt709aIBCEMQGVG/giphy.gif?cid=f576628460e1a2970bdd5587e09d730ab1cc406615067c09&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/3o6Zt709aIBCEMQGVG/100.gif?cid=f576628460e1a2970bdd5587e09d730ab1cc406615067c09&rid=100.gif",
    height: 276,
    width: 500
  }
];

export const defaultBadGiphyUrls: GiphyUrls[] = [
  {
    height: 480,
    preview:
      "https://media1.giphy.com/media/jkYhaCyVaTxqHLk2Yl/100.gif?cid=f5766284w8gwsiqdj6lskitvzy3n6lj62mykwej1wjwc8cyg&rid=100.gif",
    original:
      "https://media1.giphy.com/media/jkYhaCyVaTxqHLk2Yl/giphy.gif?cid=f5766284w8gwsiqdj6lskitvzy3n6lj62mykwej1wjwc8cyg&rid=giphy.gif",
    width: 480
  },
  {
    original:
      "https://media0.giphy.com/media/Fkmgse8OMKn9C/giphy.gif?cid=f576628457579dc0e9bb0829b84769616f5c36c4657f9572&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/Fkmgse8OMKn9C/100.gif?cid=f576628457579dc0e9bb0829b84769616f5c36c4657f9572&rid=100.gif",
    height: 242,
    width: 318
  },
  {
    original:
      "https://media1.giphy.com/media/xDoNbo6EFD3JS/giphy.gif?cid=f576628480a46999d94fdf01935ba9c13fc2c5777216847a&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/xDoNbo6EFD3JS/100.gif?cid=f576628480a46999d94fdf01935ba9c13fc2c5777216847a&rid=100.gif",
    height: 264,
    width: 480
  },
  {
    original:
      "https://media2.giphy.com/media/NKBc1zpXB47Xa/giphy.gif?cid=f5766284a3b8503807fe96fc4361d24112bcab49a3de0237&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/NKBc1zpXB47Xa/100.gif?cid=f5766284a3b8503807fe96fc4361d24112bcab49a3de0237&rid=100.gif",
    height: 169,
    width: 300
  },
  {
    original:
      "https://media1.giphy.com/media/3ohhwF34cGDoFFhRfy/giphy.gif?cid=f5766284a3b8503807fe96fc4361d24112bcab49a3de0237&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/3ohhwF34cGDoFFhRfy/100.gif?cid=f5766284a3b8503807fe96fc4361d24112bcab49a3de0237&rid=100.gif",
    height: 400,
    width: 400
  },
  {
    original:
      "https://media3.giphy.com/media/11tTNkNy1SdXGg/giphy.gif?cid=f5766284cab8aa605933fd383c01846a1de6ffe21c0fa2f5&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/11tTNkNy1SdXGg/100.gif?cid=f5766284cab8aa605933fd383c01846a1de6ffe21c0fa2f5&rid=100.gif",
    height: 278,
    width: 500
  },
  {
    original:
      "https://media0.giphy.com/media/8pMS5BXOUVZyo/giphy.gif?cid=f5766284cab8aa605933fd383c01846a1de6ffe21c0fa2f5&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/8pMS5BXOUVZyo/100.gif?cid=f5766284cab8aa605933fd383c01846a1de6ffe21c0fa2f5&rid=100.gif",
    height: 219,
    width: 390
  },
  {
    original:
      "https://media2.giphy.com/media/l1J9u3TZfpmeDLkD6/giphy.gif?cid=f5766284cab8aa605933fd383c01846a1de6ffe21c0fa2f5&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/l1J9u3TZfpmeDLkD6/100.gif?cid=f5766284cab8aa605933fd383c01846a1de6ffe21c0fa2f5&rid=100.gif",
    height: 534,
    width: 466
  },
  {
    original:
      "https://media2.giphy.com/media/EizPK3InQbrNK/giphy.gif?cid=f5766284ccc73fab3f9ba30a452d38cb5da049b6bbdd4135&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/EizPK3InQbrNK/100.gif?cid=f5766284ccc73fab3f9ba30a452d38cb5da049b6bbdd4135&rid=100.gif",
    height: 375,
    width: 500
  },
  {
    original:
      "https://media2.giphy.com/media/kC8zzkO035xMY0ShNK/giphy.gif?cid=f5766284fad3feec0da44430cf37138ff6d798d6a2753ac5&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/kC8zzkO035xMY0ShNK/100.gif?cid=f5766284fad3feec0da44430cf37138ff6d798d6a2753ac5&rid=100.gif",
    height: 480,
    width: 480
  },
  {
    original:
      "https://media0.giphy.com/media/f5Xt7shN4uihIogVSz/giphy.gif?cid=f5766284fad3feec0da44430cf37138ff6d798d6a2753ac5&rid=giphy.gif",
    preview:
      "https://media0.giphy.com/media/f5Xt7shN4uihIogVSz/100.gif?cid=f5766284fad3feec0da44430cf37138ff6d798d6a2753ac5&rid=100.gif",
    height: 480,
    width: 480
  },
  {
    original:
      "https://media1.giphy.com/media/5wWf7GW1AzV6pF3MaVW/giphy.gif?cid=f5766284b5dcf4e7ac1d666df006d145b594eab9856b3115&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/5wWf7GW1AzV6pF3MaVW/100.gif?cid=f5766284b5dcf4e7ac1d666df006d145b594eab9856b3115&rid=100.gif",
    height: 250,
    width: 432
  },
  {
    original:
      "https://media1.giphy.com/media/4ZrFRwHGl4HTELW801/giphy.gif?cid=f57662840f5b415720e12fe66400d4943f101bfe2af5e025&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/4ZrFRwHGl4HTELW801/100.gif?cid=f57662840f5b415720e12fe66400d4943f101bfe2af5e025&rid=100.gif",
    height: 480,
    width: 480
  },
  {
    original:
      "https://media1.giphy.com/media/dWhHUkuWnGxFK/giphy.gif?cid=f5766284b8edd70b08a8af64ceaa52eb8a8fd12913dce7db&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/dWhHUkuWnGxFK/100.gif?cid=f5766284b8edd70b08a8af64ceaa52eb8a8fd12913dce7db&rid=100.gif",
    height: 217,
    width: 291
  },
  {
    original:
      "https://media2.giphy.com/media/dEdmW17JnZhiU/giphy.gif?cid=f5766284b8edd70b08a8af64ceaa52eb8a8fd12913dce7db&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/dEdmW17JnZhiU/100.gif?cid=f5766284b8edd70b08a8af64ceaa52eb8a8fd12913dce7db&rid=100.gif",
    height: 180,
    width: 320
  },
  {
    original:
      "https://media3.giphy.com/media/Jq824R93JsLwZCaiSL/giphy.gif?cid=f57662843c30644678d8d87b9c79149b1f224eb639a92102&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/Jq824R93JsLwZCaiSL/100.gif?cid=f57662843c30644678d8d87b9c79149b1f224eb639a92102&rid=100.gif",
    height: 500,
    width: 500
  },
  {
    original:
      "https://media3.giphy.com/media/kuf7g0KM5UMBW/giphy.gif?cid=f576628415d8ae269e30c87911554b2927a64ae433819174&rid=giphy.gif",
    preview:
      "https://media3.giphy.com/media/kuf7g0KM5UMBW/100.gif?cid=f576628415d8ae269e30c87911554b2927a64ae433819174&rid=100.gif",
    width: 150,
    height: 200
  },
  {
    original: "https://media.giphy.com/media/3o6MbdtJUBQ62n2dBS/giphy.gif",
    preview: "https://media.giphy.com/media/3o6MbdtJUBQ62n2dBS/100.gif",
    height: 270,
    width: 480
  },
  {
    original:
      "https://media2.giphy.com/media/63MWfMm5UYyxJlhY0K/giphy.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/63MWfMm5UYyxJlhY0K/100.gif?cid=f5766284e46a0c583c298795cd7b6df6217038a15a79d5ee&rid=100.gif",
    height: 270,
    width: 480
  },
  {
    height: 292,
    original:
      "https://media4.giphy.com/media/GjR6RPcURgiL6/giphy.gif?cid=f5766284w8gwsiqdj6lskitvzy3n6lj62mykwej1wjwc8cyg&rid=giphy.gif",
    preview:
      "https://media4.giphy.com/media/GjR6RPcURgiL6/100.gif?cid=f5766284w8gwsiqdj6lskitvzy3n6lj62mykwej1wjwc8cyg&rid=100.gif",
    width: 480
  },
  {
    height: 420,
    original:
      "https://media1.giphy.com/media/3o7TKB3oifq46DDhOE/giphy.gif?cid=f576628457h4seoggi7bg1jgmz7ccibgbhp03bp976mt1vqb&rid=giphy.gif",
    preview:
      "https://media1.giphy.com/media/3o7TKB3oifq46DDhOE/100.gif?cid=f576628457h4seoggi7bg1jgmz7ccibgbhp03bp976mt1vqb&rid=100.gif",
    width: 480
  },
  {
    height: 345,
    original:
      "https://media4.giphy.com/media/1QhmDy91F9veMRLpvK/giphy.gif?cid=f576628457h4seoggi7bg1jgmz7ccibgbhp03bp976mt1vqb&rid=giphy.gif",
    preview:
      "https://media4.giphy.com/media/1QhmDy91F9veMRLpvK/100.gif?cid=f576628457h4seoggi7bg1jgmz7ccibgbhp03bp976mt1vqb&rid=100.gif",
    width: 500
  },
  {
    height: 400,
    original:
      "https://media2.giphy.com/media/Vgldw2H2uRLRXTtWhS/giphy.gif?cid=f57662848kekyfqpb41itr7a9xm937pcscpdqz8vougjd6ce&rid=giphy.gif",
    preview:
      "https://media2.giphy.com/media/Vgldw2H2uRLRXTtWhS/100.gif?cid=f57662848kekyfqpb41itr7a9xm937pcscpdqz8vougjd6ce&rid=100.gif",
    width: 480
  },
  {
    height: 179,
    original:
      "https://media4.giphy.com/media/tZiLOffTNGoak/giphy.gif?cid=f5766284r8gd3wpcaz765ao3sixjb9vyx5vzqm3e4660yudn&rid=giphy.gif",
    preview:
      "https://media4.giphy.com/media/tZiLOffTNGoak/100.gif?cid=f5766284r8gd3wpcaz765ao3sixjb9vyx5vzqm3e4660yudn&rid=100.gif",
    width: 260
  }
];
