import { PlayerId } from "./Player.schema";
import { AccountId } from "./Account.schema";
import { TeamId } from "./Team.schema";
import { SoccerIds, SoccerFormationKeys, SoccerShotTargetArea } from "..";
import { SoccerPositionNumberWithSubOut, SoccerPositionNumber } from "../misc";
import { isDevelopmentEnv } from "../internal-utils/isDevelopmentEnv";
import * as _ from "lodash";

type TypeEqualsType<T, S> = [T] extends [S] ? ([S] extends [T] ? true : false) : false;

export type SoccerGameEventId = string;
type PercentNumberBetween0And1 = number;

//Note: The opponent goal is located at (1, 0.5) while our own team goal is located at (0, 0.5)
type FieldLocation = { x: PercentNumberBetween0And1; y: PercentNumberBetween0And1 };

export enum SoccerGameEventType {
  basicTouch = "basicTouch",
  cross = "cross",
  goaliePunt = "goaliePunt",
  goalieThrow = "goalieThrow",
  goalKickLong = "goalKickLong",
  goalKickPass = "goalKickPass",
  clearance = "clearance",
  freeKickLong = "freeKickLong",
  freeKickPass = "freeKickPass",
  freeKickCross = "freeKickCross",
  stab = "stab",
  dribble = "dribble",
  offsideInfraction = "offsidePenalty",
  foulInfraction = "foulInfraction",
  handballInfraction = "handballInfraction",
  yellowCardInfraction = "yellowCardInfraction",
  redCardInfraction = "redCardInfraction",
  throwIn = "throwIn",
  throwInInfraction = "throwInInfraction",
  cornerKickShort = "cornerKickShort",
  cornerKickCross = "cornerKickCross",
  substitute = "substitute",
  startHalf = "startHalf",
  stopHalf = "stopHalf",
  postGoalKickoff = "postGoalKickoff",
  outOfBounds = "outOfBounds",
  looseBallOutOfBounds = "looseBallOutOfBounds",
  tackle = "tackle",
  missedPassTarget = "missedPassTarget",
  shot = "shot",
  shotWithGoal = "shotWithGoal",
  shotShootoutPK = "shotShootoutPK",
  header = "header",
  ownTeamGoal = "ownTeamGoal",
  forfeitInfractionPK = "forfeitInfractionPK",
  switchFormation = "switchFormation",
  shotWithGoalManualEntry = "shotWithGoalManualEntry",
  pauseGameAndStopClock = "pauseGameAndStopClock",
  //TODO: RENAME THIS FIELD: pauseGameAndKeepClockRunning
  startOfficialStoppage = "startOfficialStoppage",
  resumeGameWithDropBallOnStoppedClock = "resumeGameWithDropBallOnStoppedClock",
  resumeGameWithStartingPlayerOnStoppedClock = "resumeGameWithStartingPlayerOnStoppedClock",
  resumeGameOnStoppedClock = "resumeGameOnStoppedClock",
  //TODO: RENAME THIS FIELD: resumeGameWithDropBallOnRunningClock
  stopOfficialStoppageWithDropBall = "stopOfficialStoppageWithDropBall",
  //TODO: RENAME THIS FIELD: resumeGameWithStartingPlayerOnRunningClock
  stopOfficialStoppageWithStartingPlayer = "stopOfficialStoppageWithStartingPlayer",
  resumeGameOnRunningClock = "resumeGameOnRunningClock"
}

export enum ShotResultType {
  goalieShotBlock = "goalieShotBlock",
  goalieShotCatch = "goalieShotCatch",
  offTargetShotMiss = "offTargetShotMiss",
  fieldPlayerShotBlock = "fieldPlayerShotBlock",
  goal = "goal"
}

export interface PKShootoutScore {
  ownTeam: number;
  opponentTeam: number;
}

export type PauseEventReasons =
  | "manual-main-official-stoppage"
  | "manual-modal-official-stoppage"
  | "auto-card"
  | "auto-pk"
  | "auto-goal"
  | "game-delay"
  | "video-pause";

export type PauseEventReasonsThatResumeWithAssignmentOrDropBall = Extract<
  PauseEventReasons,
  "manual-main-official-stoppage" | "game-delay" | "auto-card"
>;

export type WhyIncludedInTimelineReasons = "free-kick" | "corner" | "cross";

export enum SoccerPossessionState {
  player = "player",
  looseBall = "looseBall",
  deadBall = "deadBall",
  unknown = "unknown"
}

/****** BASE OBJECTS ******/
interface SoccerGameEventBase {
  id: SoccerGameEventId;
  createdAtMS: number;
  playingTimeMS: number; //Begins adding up for every second the game is actually live (e.g. not in between halfs)
  //TODO: Delete gameClockTimeMS and all references to it after all clients have upgraded to app version 2.4.10 (~October 1, 2021) or later.
  /**
   * @deprecated Instead use the event's createdAtMS and the proper SoccerGameClock.ts game clock computation function.
   */
  gameClockTimeMS: number;
  soccerGameId: string;
  teamId: string;
  undoSetKey?: string; //Random string key that identifies events that should be undone simultaneously (e.g. multiple events created in a wizard)
  recorderAccountId: AccountId;
}

interface SoccerGameEventBaseWithPlayer extends SoccerGameEventBase {
  playerTeamId: TeamId | SoccerIds.opponentTeamId;
  playerId:
    | PlayerId
    | SoccerIds.ownTeamGenericPlayer
    | SoccerIds.opponentFieldPlayer
    | SoccerIds.opponentGoalie
    | SoccerIds.opponentGenericPlayer;
}

interface SoccerGameEventPlayerAndFieldLocation extends SoccerGameEventBaseWithPlayer {
  fieldLocation: FieldLocation;
}

/****** GAME EVENT DEFINITIONS ******/

/****** Game Events with NO players ******/
export interface SGE_stopHalf extends SoccerGameEventBase {
  type: SoccerGameEventType.stopHalf;
  includeInTimeline: true;
  endsGame?: boolean;
  pkScore?: PKShootoutScore;
}

export interface SGE_substitute extends SoccerGameEventBase {
  type: SoccerGameEventType.substitute;
  includeInTimeline: true;
  playerIdToNewPosition: { [playerId in string]?: SoccerPositionNumberWithSubOut };
  //Make this required if all data before July 1st, 2020 is ever destroyed
  playerIdToOldPosition?: { [playerId in string]?: SoccerPositionNumberWithSubOut };
}

export interface SGE_switchFormation extends SoccerGameEventBase {
  type: SoccerGameEventType.switchFormation;
  newFormation: SoccerFormationKeys;
  //Substitutions that must occur when max field players is less than 11 to ensure the same players are still playing since the position numbers playing on the field may change
  playerIdToNewPosition: { [playerId in string]?: SoccerPositionNumber };
}

export interface SGE_forfeitInfractionPK extends SoccerGameEventBase {
  type: SoccerGameEventType.forfeitInfractionPK;
  playerTeamId: string;
  includeInTimeline: true;
}

export interface SGE_looseBallOutOfBounds extends SoccerGameEventBase {
  type: SoccerGameEventType.looseBallOutOfBounds;
  fieldLocation: FieldLocation;
}

/** Game Events WITH Players **/
export interface SGE_basicTouch extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.basicTouch;
  includeInTimeline?: true;
  whyIncludedInTimeline?: WhyIncludedInTimelineReasons;
}
export interface SGE_cross extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.cross;
  includeInTimeline: true;
}
export interface SGE_goalKickLong extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.goalKickLong;
}
export interface SGE_goaliePunt extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.goaliePunt;
}
export interface SGE_goalieThrow extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.goalieThrow;
}
export interface SGE_clearance extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.clearance;
  includeInTimeline?: true;
  whyIncludedInTimeline?: WhyIncludedInTimelineReasons;
}
export interface SGE_goalKickPass extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.goalKickPass;
}
export interface SGE_stab extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.stab;
}
export interface SGE_dribble extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.dribble;
}
export interface SGE_cornerKickShort extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.cornerKickShort;
  includeInTimeline: true;
}
export interface SGE_cornerKickCross extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.cornerKickCross;
  includeInTimeline: true;
}
export interface SGE_startHalf extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.startHalf;
  includeInTimeline: true;
  startsGame?: boolean;
}
export interface SGE_postGoalKickoff extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.postGoalKickoff;
  includeInTimeline: true; // We need this to help with deleting goals
}
export interface SGE_tackle extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.tackle;
}
export interface SGE_missedPassTarget extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.missedPassTarget;
}
export interface SGE_ownTeamGoal extends SoccerGameEventBaseWithPlayer {
  //Note: This event is a bit weird. The playerId actually references a generic player on the team THAT scored, not the dummy who scored on their own goal
  type: SoccerGameEventType.ownTeamGoal;
  includeInTimeline: true;
  idOfPlayerWhoShotOwnGoal: string;
}

export interface SGE_header extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.header;
}
export interface SGE_redCardInfraction extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.redCardInfraction;
  includeInTimeline: true;
}
export interface SGE_yellowCardInfraction extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.yellowCardInfraction;
  includeInTimeline: true;
}

/** Game Events WITH Players AND location**/
export interface SGE_freeKickLong extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.freeKickLong;
  includeInTimeline: true;
}
export interface SGE_freeKickPass extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.freeKickPass;
  includeInTimeline: true;
}
export interface SGE_freeKickCross extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.freeKickCross;
  includeInTimeline: true;
}
export interface SGE_throwIn extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.throwIn;
}
export interface SGE_throwInInfraction extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.throwInInfraction;
}
export interface SGE_outOfBounds extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.outOfBounds;
}
export interface SGE_foulInfraction extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.foulInfraction;
  didResultInPK?: boolean;
  includeInTimeline: true;
}
export interface SGE_handballInfraction extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.handballInfraction;
  didResultInPK?: boolean;
  includeInTimeline: true;
}
export interface SGE_offsideInfraction extends SoccerGameEventPlayerAndFieldLocation {
  type: SoccerGameEventType.offsideInfraction;
  includeInTimeline: true;
}

/** Shot Game Events **/
interface ShotBase extends SoccerGameEventBaseWithPlayer {
  includeInTimeline: true;
  shotIsOnTarget: boolean;
  shotTargetArea: SoccerShotTargetArea;
  defendingGoaliePlayerId?: string;
  attackingThirdFieldLocation: {
    x: PercentNumberBetween0And1;
    y: PercentNumberBetween0And1;
  };
}

export interface SGE_shot extends ShotBase {
  type: SoccerGameEventType.shot;
  shotTargetLocation?: {
    x: PercentNumberBetween0And1;
    y: PercentNumberBetween0And1;
  };
  shotReason: "run-of-play" | "infraction-pk" | "free-kick";
  shotResultType: ShotResultType;
}

export interface SGE_shotWithGoal extends ShotBase {
  type: SoccerGameEventType.shotWithGoal;
  shotTargetLocation: {
    x: PercentNumberBetween0And1;
    y: PercentNumberBetween0And1;
  };
  shotReason: "run-of-play" | "infraction-pk" | "free-kick";
  assistingPlayerId?: PlayerId;
}

export interface SGE_shotWithGoalManualEntry extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.shotWithGoalManualEntry;
  shotIsOnTarget: true;
  shotTargetArea: SoccerShotTargetArea.unknown;
  shotReason: "unknown";
  defendingGoaliePlayerId?: string;
  assistingPlayerId?: PlayerId;
  idOfPlayerWhoShotOwnGoal?: string;
  includeInTimeline: true;
}

export interface SGE_shotShootoutPK extends ShotBase {
  type: SoccerGameEventType.shotShootoutPK;
  shotTargetLocation: {
    x: PercentNumberBetween0And1;
    y: PercentNumberBetween0And1;
  };
  shotDidScore: boolean;
  shotResultType: ShotResultType;
}

/** Pause/Resume Events */
export interface SGE_pauseGameAndStopClock extends SoccerGameEventBase {
  type: SoccerGameEventType.pauseGameAndStopClock;
  reason: PauseEventReasons;
  includeInTimeline: true;
}

export interface SGE_startOfficialStoppage extends SoccerGameEventBase {
  type: SoccerGameEventType.startOfficialStoppage;
  reason: PauseEventReasons;
  includeInTimeline: true;
}

export interface SGE_resumeGameWithDropBallOnStoppedClock extends SoccerGameEventBase {
  type: SoccerGameEventType.resumeGameWithDropBallOnStoppedClock;
  pauseReason: PauseEventReasonsThatResumeWithAssignmentOrDropBall;
  includeInTimeline: true;
}
export interface SGE_resumeGameWithStartingPlayerOnStoppedClock extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock;
  pauseReason: PauseEventReasonsThatResumeWithAssignmentOrDropBall;
  includeInTimeline: true;
}
export interface SGE_resumeGameOnStoppedClock extends SoccerGameEventBase {
  type: SoccerGameEventType.resumeGameOnStoppedClock;
  pauseReason: PauseEventReasons;
  includeInTimeline: true;
}
export interface SGE_stopOfficialStoppageWithDropBall extends SoccerGameEventBase {
  type: SoccerGameEventType.stopOfficialStoppageWithDropBall;
  pauseReason: PauseEventReasonsThatResumeWithAssignmentOrDropBall;
  includeInTimeline: true;
}
export interface SGE_stopOfficialStoppageWithStartingPlayer extends SoccerGameEventBaseWithPlayer {
  type: SoccerGameEventType.stopOfficialStoppageWithStartingPlayer;
  pauseReason: PauseEventReasonsThatResumeWithAssignmentOrDropBall;
  includeInTimeline: true;
}
export interface SGE_resumeGameOnRunningClock extends SoccerGameEventBase {
  //This event is utterly worthless. Only here to make people feel good about themselves
  type: SoccerGameEventType.resumeGameOnRunningClock;
  pauseReason: PauseEventReasons;
  includeInTimeline: true;
}

export type SoccerGameEvent =
  | SGE_basicTouch
  | SGE_cross
  | SGE_goaliePunt
  | SGE_goalieThrow
  | SGE_goalKickLong
  | SGE_goalKickPass
  | SGE_clearance
  | SGE_freeKickLong
  | SGE_freeKickPass
  | SGE_freeKickCross
  | SGE_stab
  | SGE_dribble
  | SGE_offsideInfraction
  | SGE_foulInfraction
  | SGE_handballInfraction
  | SGE_yellowCardInfraction
  | SGE_redCardInfraction
  | SGE_throwIn
  | SGE_throwInInfraction
  | SGE_cornerKickShort
  | SGE_cornerKickCross
  | SGE_substitute
  | SGE_startHalf
  | SGE_stopHalf
  | SGE_postGoalKickoff
  | SGE_outOfBounds
  | SGE_looseBallOutOfBounds
  | SGE_tackle
  | SGE_missedPassTarget
  | SGE_shot
  | SGE_shotWithGoal
  | SGE_shotWithGoalManualEntry
  | SGE_shotShootoutPK
  | SGE_header
  | SGE_ownTeamGoal
  | SGE_forfeitInfractionPK
  | SGE_switchFormation
  | SGE_pauseGameAndStopClock
  | SGE_startOfficialStoppage
  | SGE_resumeGameWithDropBallOnStoppedClock
  | SGE_resumeGameWithStartingPlayerOnStoppedClock
  | SGE_resumeGameOnStoppedClock
  | SGE_stopOfficialStoppageWithDropBall
  | SGE_stopOfficialStoppageWithStartingPlayer
  | SGE_resumeGameOnRunningClock;

export type SoccerGameEventTypeToSGE = {
  [SoccerGameEventType.basicTouch]: SGE_basicTouch;
  [SoccerGameEventType.cross]: SGE_cross;
  [SoccerGameEventType.goaliePunt]: SGE_goaliePunt;
  [SoccerGameEventType.goalieThrow]: SGE_goalieThrow;
  [SoccerGameEventType.goalKickLong]: SGE_goalKickLong;
  [SoccerGameEventType.goalKickPass]: SGE_goalKickPass;
  [SoccerGameEventType.clearance]: SGE_clearance;
  [SoccerGameEventType.freeKickLong]: SGE_freeKickLong;
  [SoccerGameEventType.freeKickPass]: SGE_freeKickPass;
  [SoccerGameEventType.freeKickCross]: SGE_freeKickCross;
  [SoccerGameEventType.stab]: SGE_stab;
  [SoccerGameEventType.dribble]: SGE_dribble;
  [SoccerGameEventType.offsideInfraction]: SGE_offsideInfraction;
  [SoccerGameEventType.foulInfraction]: SGE_foulInfraction;
  [SoccerGameEventType.handballInfraction]: SGE_handballInfraction;
  [SoccerGameEventType.yellowCardInfraction]: SGE_yellowCardInfraction;
  [SoccerGameEventType.redCardInfraction]: SGE_redCardInfraction;
  [SoccerGameEventType.throwIn]: SGE_throwIn;
  [SoccerGameEventType.throwInInfraction]: SGE_throwInInfraction;
  [SoccerGameEventType.cornerKickShort]: SGE_cornerKickShort;
  [SoccerGameEventType.cornerKickCross]: SGE_cornerKickCross;
  [SoccerGameEventType.substitute]: SGE_substitute;
  [SoccerGameEventType.startHalf]: SGE_startHalf;
  [SoccerGameEventType.stopHalf]: SGE_stopHalf;
  [SoccerGameEventType.postGoalKickoff]: SGE_postGoalKickoff;
  [SoccerGameEventType.outOfBounds]: SGE_outOfBounds;
  [SoccerGameEventType.looseBallOutOfBounds]: SGE_looseBallOutOfBounds;
  [SoccerGameEventType.tackle]: SGE_tackle;
  [SoccerGameEventType.missedPassTarget]: SGE_missedPassTarget;
  [SoccerGameEventType.shot]: SGE_shot;
  [SoccerGameEventType.shotWithGoal]: SGE_shotWithGoal;
  [SoccerGameEventType.shotWithGoalManualEntry]: SGE_shotWithGoalManualEntry;
  [SoccerGameEventType.shotShootoutPK]: SGE_shotShootoutPK;
  [SoccerGameEventType.header]: SGE_header;
  [SoccerGameEventType.ownTeamGoal]: SGE_ownTeamGoal;
  [SoccerGameEventType.forfeitInfractionPK]: SGE_forfeitInfractionPK;
  [SoccerGameEventType.switchFormation]: SGE_switchFormation;
  [SoccerGameEventType.pauseGameAndStopClock]: SGE_pauseGameAndStopClock;
  [SoccerGameEventType.startOfficialStoppage]: SGE_startOfficialStoppage;
  [SoccerGameEventType.resumeGameWithDropBallOnStoppedClock]: SGE_resumeGameWithDropBallOnStoppedClock;
  [SoccerGameEventType.resumeGameWithStartingPlayerOnStoppedClock]: SGE_resumeGameWithStartingPlayerOnStoppedClock;
  [SoccerGameEventType.resumeGameOnStoppedClock]: SGE_resumeGameOnStoppedClock;
  [SoccerGameEventType.stopOfficialStoppageWithDropBall]: SGE_stopOfficialStoppageWithDropBall;
  [SoccerGameEventType.stopOfficialStoppageWithStartingPlayer]: SGE_stopOfficialStoppageWithStartingPlayer;
  [SoccerGameEventType.resumeGameOnRunningClock]: SGE_resumeGameOnRunningClock;
};

//Only exists to have typescript throw an error if some event type doesn't have unique interface...
if (isDevelopmentEnv()) {
  const assertAllEventsArePresentInSoccerGameEventType: TypeEqualsType<
    { type: SoccerGameEventType },
    Pick<SoccerGameEvent, "type">
  > = true;

  const assertAllEventsArePresentInSoccerGameEventTypeToSGE: TypeEqualsType<
    SoccerGameEventType,
    keyof SoccerGameEventTypeToSGE
  > = true;
}
