import { OrgId, Team__StaffTypes } from "../collections";
import { GENDERS } from "../constants";
import { isDevelopmentEnv } from "../internal-utils/isDevelopmentEnv";

export interface CommunicationTag__MinimalBase {
  lookupGroup: string; //unique identifier for the group the tag belongs to
  lookupKey: string; //unique identifier for the tag
  estimatedNumAccounts: number;
  prettyName: string; //concatenation of entityName and prettyFilters...
}

export interface CommunicationTag__Base extends CommunicationTag__MinimalBase {
  entityName: string;
  prettyFilters: string; //A string representation of the filters. E.g. "all users", "coaching staff"
}

export interface CommunicationTag__Team extends CommunicationTag__Base {
  tagType: "teamTag";
  topStaffInfo?: {
    fullName: string;
    role: Team__StaffTypes;
  };
  gender: GENDERS;
  birthYear?: string;
  teamId: string;
  accountFilters?: CommunicationTag__Constants__AccountFilter[];
}

export interface CommunicationTag__Org extends CommunicationTag__Base {
  tagType: "orgTag";
  orgId: OrgId;
  teamFilters?: CommunicationTag__Constants__TeamFilter[];
  accountFilters?: CommunicationTag__Constants__AccountFilter[];
}

export interface CommunicationTag__OrgAdmins extends CommunicationTag__Base {
  tagType: "orgAdminsTag";
  orgId: OrgId;
  orgAdminsFilterType: "all"; //Maybe eventually add more options if need be...
}

export type CommunicationTag__Constants__TeamFilter =
  | CommunicationTag__Constants__TeamFilter__Gender
  | CommunicationTag__Constants__TeamFilter__BirthYear;

export type CommunicationTag__Constants__TeamFilter__Gender = {
  filterType: "gender";
  gender: GENDERS;
};

export type CommunicationTag__Constants__TeamFilter__BirthYear = {
  filterType: "birthYear";
  birthYear: string;
};

export type CommunicationTag__Constants__AccountFilter__Squad = {
  filterType: "squad";
  squad: "a" | "b" | "c";
};

export type CommunicationTag__Constants__AccountFilter__TeamRole = {
  filterType: "teamRole";
  role: CommunicationTag__Constants__TeamRolesEnum;
};

export type CommunicationTag__Constants__AccountFilter =
  | CommunicationTag__Constants__AccountFilter__Squad
  | CommunicationTag__Constants__AccountFilter__TeamRole;

export type CommunicationTag__Constants__GenericFilter =
  | CommunicationTag__Constants__AccountFilter
  | CommunicationTag__Constants__TeamFilter;

export enum CommunicationTag__Constants__TeamRolesEnum {
  "headCoach" = "headCoach",
  "coaches" = "coaches",
  "staff" = "staff",
  "teamAdmins" = "teamAdmins",
  "staffAndParents" = "staffAndParents",
  "staffAndPlayers" = "staffAndPlayers"
}

export type CommunicationTag = CommunicationTag__Team | CommunicationTag__Org | CommunicationTag__OrgAdmins;
