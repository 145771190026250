import { SoccerPositionTypes, SoccerPositionNumber } from "./SoccerConstants";
import { isDevelopmentEnv } from "../internal-utils/isDevelopmentEnv";
import * as _ from "lodash";

export enum SoccerFormationKeys {
  "1-4-3-3" = "1-4-3-3",
  "1-4-2-1-3" = "1-4-2-1-3",
  "1-4-2-3-1" = "1-4-2-3-1",
  "1-4-4-2" = "1-4-4-2",
  "1-4-1-4-1" = "1-4-1-4-1",
  "1-4-4-1-1" = "1-4-4-1-1",
  "1-3-4-3" = "1-3-4-3",
  "1-3-5-2" = "1-3-5-2",
  "1-3-4-2" = "1-3-4-2",
  "1-3-2-3" = "1-3-2-3",
  "1-3-3-2" = "1-3-3-2",
  "1-2-1-4-1" = "1-2-1-4-1",
  "1-3-3-1" = "1-3-3-1",
  "1-2-3-2" = "1-2-3-2",
  "1-4-1-2-1" = "1-4-1-2-1",
  "1-4-3-1" = "1-4-3-1",
  "1-3-1-2" = "1-3-1-2",
  "1-2-5-1" = "1-2-5-1",
  "1-2-2-2" = "1-2-2-2",
  "1-2-4" = "1-2-4",
  "1-2-3-2-1" = "1-2-3-2-1",
  "1-2-4-2" = "1-2-4-2",
  "1-2-3-1-2" = "1-2-3-1-2",
  "1-2-1-3-1" = "1-2-1-3-1",
  "1-2-3-1" = "1-2-3-1",
  "1-3-2-1" = "1-3-2-1",
  "1-2-2-1" = "1-2-2-1",
  "1-2-2" = "1-2-2",
  "0-2-2" = "0-2-2",
  "0-1-2-1" = "0-1-2-1",
  "0-1-2" = "0-1-2",
  "0-2-1" = "0-2-1",
  "0-1-1-1" = "0-1-1-1",
  "1-5-4-1" = "1-5-4-1",
  "1-3-1-3-1" = "1-3-1-3-1",
  "1-4-3-1-2" = "1-4-3-1-2"
}

if (isDevelopmentEnv()) {
  if (_.uniq(Object.values(SoccerFormationKeys)).length !== Object.values(SoccerFormationKeys).length) {
    throw new Error("Possible duplicates in SoccerFormationKeys");
  }
}

export const SoccerFormationsByKey: Record<SoccerFormationKeys, { [num in SoccerPositionNumber]?: string }> = {
  // Maps the squad number to the column grid number
  // 11v11
  [SoccerFormationKeys["1-4-3-3"]]: {
    1: "00",
    2: "13",
    3: "10",
    4: "12",
    5: "11",
    6: "20",
    7: "42",
    8: "31",
    9: "41",
    10: "30",
    11: "40"
  },
  [SoccerFormationKeys["1-4-2-1-3"]]: {
    1: "00",
    2: "13",
    3: "10",
    4: "12",
    5: "11",
    6: "20",
    7: "40",
    8: "21",
    9: "41",
    10: "30",
    11: "42"
  },
  [SoccerFormationKeys["1-4-2-3-1"]]: {
    1: "00",
    2: "13",
    3: "10",
    4: "12",
    5: "11",
    6: "20",
    7: "32",
    8: "21",
    9: "40",
    10: "31",
    11: "30"
  },
  [SoccerFormationKeys["1-4-4-2"]]: {
    1: "00",
    2: "13",
    3: "10",
    4: "12",
    5: "11",
    6: "21",
    7: "23",
    8: "22",
    9: "31",
    10: "30",
    11: "20"
  },
  [SoccerFormationKeys["1-4-1-4-1"]]: {
    1: "00",
    2: "13",
    3: "10",
    4: "12",
    5: "11",
    6: "20",
    7: "33",
    8: "32",
    9: "40",
    10: "31",
    11: "30"
  },
  [SoccerFormationKeys["1-4-4-1-1"]]: {
    1: "00",
    2: "13",
    3: "10",
    4: "12",
    5: "11",
    6: "21",
    7: "23",
    8: "22",
    9: "40",
    10: "30",
    11: "20"
  },
  [SoccerFormationKeys["1-3-4-3"]]: {
    1: "00",
    2: "12",
    3: "10",
    4: "22",
    5: "11",
    6: "21",
    7: "23",
    8: "32",
    9: "31",
    10: "30",
    11: "20"
  },
  [SoccerFormationKeys["1-3-5-2"]]: {
    1: "00",
    2: "12",
    3: "10",
    4: "20",
    5: "11",
    6: "21",
    7: "32",
    8: "31",
    9: "40",
    10: "41",
    11: "30"
  },
  [SoccerFormationKeys["1-5-4-1"]]: {
    1: "00",
    2: "10",
    3: "11",
    4: "12",
    5: "21",
    6: "22",
    7: "23",
    8: "30",
    9: "31",
    10: "32",
    11: "40"
  },
  [SoccerFormationKeys["1-4-3-1-2"]]: {
    1: "00",
    2: "13",
    3: "10",
    4: "12",
    5: "11",
    6: "21",
    7: "22",
    8: "30",
    9: "41",
    10: "40",
    11: "20"
  },
  //10v10
  [SoccerFormationKeys["1-3-4-2"]]: ["00", "12", "10", "20", "11", "21", "32", "31", "40", "30"].reduce(numsToPositions, {}),
  //9v9
  [SoccerFormationKeys["1-3-2-3"]]: ["00", "12", "10", "11", "21", "32", "20", "31", "30"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-3-3-2"]]: ["00", "12", "10", "11", "22", "21", "30", "31", "20"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-1-4-1"]]: ["00", "11", "10", "20", "33", "32", "40", "31", "30"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-4-1-2-1"]]: ["00", "10", "11", "12", "13", "30", "31", "32", "40"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-4-3-1"]]: ["00", "10", "11", "12", "13", "20", "21", "22", "30"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-3-2-1"]]: ["00", "10", "11", "20", "21", "22", "30", "31", "40"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-4-2"]]: ["00", "10", "11", "20", "21", "22", "23", "30", "31"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-3-1-2"]]: ["00", "10", "11", "20", "21", "22", "30", "40", "41"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-5-1"]]: ["00", "10", "11", "20", "21", "22", "23", "30", "40"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-3-1-3-1"]]: ["00", "10", "11", "12", "20", "30", "31", "32", "40"].reduce(numsToPositions, {}),

  //8v8
  [SoccerFormationKeys["1-3-3-1"]]: ["00", "12", "10", "11", "22", "30", "21", "20"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-1-3-1"]]: ["00", "12", "10", "20", "30", "31", "40", "32"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-3-2"]]: ["00", "10", "11", "22", "30", "21", "20", "31"].reduce(numsToPositions, {}),
  //7v7
  [SoccerFormationKeys["1-2-3-1"]]: ["00", "11", "10", "22", "21", "30", "20"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-3-2-1"]]: ["00", "12", "10", "11", "21", "20", "30"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-3-1-2"]]: ["00", "10", "11", "12", "20", "30", "31"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-2-2"]]: ["00", "10", "11", "20", "30", "40", "41"].reduce(numsToPositions, {}),
  [SoccerFormationKeys["1-2-4"]]: ["00", "10", "11", "20", "30", "31", "40"].reduce(numsToPositions, {}),
  //6v6
  [SoccerFormationKeys["1-2-2-1"]]: ["00", "10", "11", "22", "20", "30"].reduce(numsToPositions, {}),
  //5v5
  [SoccerFormationKeys["1-2-2"]]: ["00", "10", "11", "20", "21"].reduce(numsToPositions, {}),
  //4v4
  [SoccerFormationKeys["0-2-2"]]: ["00", "01", "10", "11"].reduce(numsToPositionsWithoutGoalie, {}),
  [SoccerFormationKeys["0-1-2-1"]]: ["00", "10", "11", "20"].reduce(numsToPositionsWithoutGoalie, {}),
  //3v3
  [SoccerFormationKeys["0-1-2"]]: ["00", "10", "11"].reduce(numsToPositionsWithoutGoalie, {}),
  [SoccerFormationKeys["0-2-1"]]: ["00", "01", "10"].reduce(numsToPositionsWithoutGoalie, {}),
  [SoccerFormationKeys["0-1-1-1"]]: ["00", "10", "20"].reduce(numsToPositionsWithoutGoalie, {})
};

function numsToPositions(acc: Record<any, any>, v: any, i: number, arr: any[]) {
  acc[i + 1] = v;

  return acc;
}

function numsToPositionsWithoutGoalie(acc: Record<any, any>, v: any, i: number, arr: any[]) {
  acc[i + 2] = v;

  return acc;
}

export function positionNumberToPositionType(a: {
  playerPosition: SoccerPositionNumber;
  formationKey: SoccerFormationKeys;
}): SoccerPositionTypes | undefined {
  /**
   * First column is GK
   * Next column is defender
   * Furthest column is forward
   * All others are mids
   *
   */
  const formationInfo = SoccerFormationsByKey[a.formationKey];
  const hasGoalie = !!formationInfo[1];
  const colRow = formationInfo[a.playerPosition] || "";
  let numColumns = _.uniq(Object.values(formationInfo).map((c: any) => c[0])).length;

  if (!colRow) {
    return;
  }

  let [col, __] = colRow.split("").map(Number);

  if (!hasGoalie) {
    col++;
    numColumns++;
  }

  if (col === 0) {
    return SoccerPositionTypes.goalkeeper;
  } else if (col === 1) {
    return SoccerPositionTypes.defender;
  } else if (col === numColumns - 1) {
    return SoccerPositionTypes.forward;
  } else {
    return SoccerPositionTypes.midfielder;
  }
}

export function getFormationSize(formationKey: SoccerFormationKeys) {
  return Object.keys(SoccerFormationsByKey[formationKey]).length;
}

type FormationsByMaxPlayers = { [formationKey in string]: Record<SoccerPositionNumber, string> };
export function getFormationsByMaxPlayers(maxPlayers = 11): FormationsByMaxPlayers {
  const formations: FormationsByMaxPlayers = {};
  Object.keys(SoccerFormationsByKey).forEach(formationKey => {
    const count = getFormationSize(formationKey as SoccerFormationKeys);

    if (count === maxPlayers) {
      formations[formationKey as SoccerFormationKeys] = SoccerFormationsByKey[formationKey as SoccerFormationKeys] as any;
    }
  });

  return formations;
}
