import { z } from "zod";
import { GoogleAddress, GENDERS, GoogleAddressZod } from "../constants";
import { OpenOrgEventRegistrationQuestionZod } from "./OpenOrgEventRegistration.schema";

export type OpenOrgEventId = string;
export const OpenOrgEventSessionZod = z.object({
  sessionTimes: z.array(
    z.object({
      date: z.string(),
      startTime: z.string(),
      endTime: z.string()
    })
  ),
  birthYears: z.string().array(),
  genders: z.nativeEnum(GENDERS).array(),
  location: GoogleAddressZod,
  locationDetails: z.string()
});

export const OpenOrgEventZod = z.object({
  id: z.string(),
  type: z.enum(["tryout", "camp"]), //Eventually add camps, etc
  orgId: z.string(),
  title: z.string(),
  details: z.string().optional(),
  isPhotoRequired: z.boolean(),
  timezone: z.string(),
  signupAvailabilityStartDatetime: z.string(),
  registrationDeadlineDatetime: z.string().optional(),
  orgTeamTagId: z.string().optional(),
  slug: z.string(),
  replyToEmailAddress: z.string().optional(),
  feeAmountUSD: z.string().optional(),
  derived: z
    .object({
      endOfFinalSession: z.string() // YYYY-MM-DDTHH:MM
    })
    .optional(),
  chargeProcessingFees: z.boolean().optional(), // When true, the person paying has to pay the processing fees
  pointOfContact: z
    .object({
      name: z.string().optional(),
      phoneNumber: z.string().optional(),
      email: z.string().optional()
    })
    .optional(),
  customSections: OpenOrgEventRegistrationQuestionZod.array().optional(),
  sessions: OpenOrgEventSessionZod.array().optional(),
  answeredCustomSectionIds: z.record(z.string(), z.literal(true)).optional(),
  lastRegistrationAtMS: z.number().optional()
});

export type OpenOrgEvent = z.infer<typeof OpenOrgEventZod>;
export type OpenOrgEventSession = z.infer<typeof OpenOrgEventSessionZod>;

export interface OpenOrgEventGroup {
  birthYear: string;
  gender: GENDERS;
  tag?: string;
}
