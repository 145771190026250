import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const BasketballBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.basketball
} as const;

const BasketballBaseWithPlayerObj = {
  ...BasketballBaseObj,
  playerId: "" as ScoreKeepingPlayerId
};

export const SKE_basketballObj = {
  SKE_basketball2PtFG: {
    ...BasketballBaseWithPlayerObj,
    type: "2PtFG" as const,
    points: 2
  },
  SKE_basketballStartPeriod: {
    ...BasketballBaseObj,
    type: "startPeriod" as const,
    starters: {} as undefined | { [playerId in string]: true }
  },
  SKE_basketballEndPeriod: {
    ...BasketballBaseObj,
    type: "endPeriod" as const,
    endsGame: true as true | undefined
  },
  SKE_soccerSubstitute: {
    ...BasketballBaseObj,
    type: "substitute" as const,
    subbingIn: {} as Record<string, true>,
    subbingOut: {} as Record<string, true>
  },
  SKE_basketballAssist: {
    ...BasketballBaseWithPlayerObj,
    type: "assist" as const
  },

  SKE_basketball3PtFG: {
    ...BasketballBaseWithPlayerObj,
    type: "3PtFG" as const,
    points: 2
  },
  SKE_basketballFreeThrow: {
    ...BasketballBaseWithPlayerObj,
    type: "freeThrow" as const,
    points: 1
  },
  SKE_basketball2PtFGMiss: {
    ...BasketballBaseWithPlayerObj,
    type: "2PtFGMiss" as const,
    points: 2
  },
  SKE_basketball3PtFGMiss: {
    ...BasketballBaseWithPlayerObj,
    type: "3PtFGMiss" as const
  },
  SKE_basketballFreeThrowMiss: {
    ...BasketballBaseWithPlayerObj,
    type: "freeThrowMiss" as const
  },
  SKE_basketballTurnover: {
    ...BasketballBaseWithPlayerObj,
    type: "turnover" as const
  },
  SKE_basketballSteal: {
    ...BasketballBaseWithPlayerObj,
    type: "steal" as const
  },
  SKE_basketballFoul: {
    ...BasketballBaseWithPlayerObj,
    type: "foul" as const
  }
} as const;

export type SKE_basketball = Values<NullableToOptional<typeof SKE_basketballObj>>;
