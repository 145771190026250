import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const WaterPoloBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.waterPolo as const
} as const;

const WaterPoloBaseWithPlayerObj = {
  ...WaterPoloBaseObj,
  playerId: "" as ScoreKeepingPlayerId
};

export const SKE_waterPoloObj = {
  SKE_waterPoloStartPeriod: {
    ...WaterPoloBaseObj,
    type: "startPeriod" as const,
    starters: {} as undefined | Record<string, true>
  },
  SKE_waterPoloEndPeriod: {
    ...WaterPoloBaseObj,
    type: "endPeriod" as const,
    endsGame: true as true | undefined
  },
  SKE_waterPoloSubstitute: {
    ...WaterPoloBaseObj,
    type: "substitute" as const,
    subbingIn: {} as Record<string, true>,
    subbingOut: {} as Record<string, true>
  },
  SKE_waterPoloAssist: {
    ...WaterPoloBaseWithPlayerObj,
    type: "assist" as const
  },
  SKE_waterPoloGoal: {
    ...WaterPoloBaseWithPlayerObj,
    type: "goal" as const
  },
  SKE_waterPoloMissedShot: {
    ...WaterPoloBaseWithPlayerObj,
    type: "missedShot" as const
  },
  SKE_waterPoloFoul: {
    ...WaterPoloBaseWithPlayerObj,
    type: "foul" as const,
    foulType: "" as "ordinaryFoul" | "exclusionPersonalFoul" | "penaltyPersonalFoul"
  }
} as const;

export type SKE_waterPolo = Simplify<Values<NullableToOptional<typeof SKE_waterPoloObj>>>;
