import { OrgId, PlayerId, TeamId } from "../collections";
import { ORG_FUNDRAISER_VISIBILITY_STATUS } from "../constants";

interface FlexDonationBase {
  date: number;
  donorName: string;
  message?: string;
  amount: number;
}

interface FlexDonationCash extends FlexDonationBase {
  type: "cash";
}

interface FlexDonationProduct extends FlexDonationBase {
  type: "product";
  productName: string;
}

export type FlexDonation = FlexDonationCash | FlexDonationProduct;

export interface FundraisingOrgTopPlayer {
  playerId: PlayerId;
  amount: number;
  flexTeamId: number;
  place: number;
}

export interface FundraisingTeamTopPlayer {
  playerId: PlayerId;
  amount: number;
  place: number;
  pageViews: number;
}

export interface FundraisingOrgTopTeam {
  teamId: TeamId;
  amount: number;
  name: string;
  flexTeamId: number;
  pageViews: number;
  place: number;
  avgPerPlayer: number;
}

export interface PlayerFundraisingData {
  goal: number;
  shareMessage: string;
  donations: FlexDonation[];
  numPlayersInOrg?: number;
  playerRankInOrg?: number;
  pageViews: number;
  playerUUID: string;
  flexPlayerId: number;
  referrals: FundraisingReferral[];
}

export interface TeamFundraisingData {
  playerAmountsRaised: FundraisingTeamTopPlayer[];
  goal: number;
  teamPrizes: FundraisingPrize[];
  teamRankInOrg?: number;
  numTeamsInOrg?: number;
  flexFundraiserSlug: string;
  orgFundraiserImageUrl?: string;
  amountRaised: number;
  donations: FlexDonation[];
}

export interface OrgFundraisingData {
  topPlayers: FundraisingOrgTopPlayer[];
  orgPrizes: FundraisingPrize[];
  teamAmountsRaised: FundraisingOrgTopTeam[];
  numPlayersWhoHaveRaised: number;
  averageRaisedPerPlayer: number;
  goal: number;
  numPlayers: number;
}

export enum PRIZE_TYPES {
  playerAmount = "player_amount",
  teamAmount = "team_amount",
  playerRankOnTeam = "player_rank_on_team",
  teamRankInOrg = "team_ranking_on_org",
  playerRankInOrg = "player_ranking_on_org",
  pageViews = "page_views",
  referrals = "referrals"
}

export interface FundraisingPrize {
  type: PRIZE_TYPES;
  name: string;
  description: string | null;
  amount: number;
  url?: string;
  prizeId: number;
  isCustom: boolean;
}

interface FundraiserBasicDataBase {
  flexTeamId: number;
  fundraiserStatus: "active" | "inactive";
  fundraiserVisibilityStatus: ORG_FUNDRAISER_VISIBILITY_STATUS;
  fundraiserName: string;
  endDateMS: number;
  fundsGoToTeam: boolean;
}

export interface TeamFundraiserBasicData extends FundraiserBasicDataBase {
  type: "team";
  teamId: string;
  fundraiserId: number;
  orgFundraiserId?: number;
  teamSponsorshipPlayerPercent: number;
}

export interface OrgFundraiserBasicDataBase extends FundraiserBasicDataBase {
  type: "org";
  orgId: OrgId;
  orgFundraiserId: number;
  orgFundraiserImageUrl?: string;
  orgGoal: number;
}

export interface OrgFundraiserCommissionPercentages {
  orgSponsorshipPlayerPercent: number;
  orgCommissionPercent: number;
  teamSponsorshipPlayerPercent: number;
}

export type OrgFundraiserBasicData = OrgFundraiserBasicDataBase & OrgFundraiserCommissionPercentages;

export type FundraiserBasicData = OrgFundraiserBasicData | TeamFundraiserBasicData;

export interface FundraisingReferral {
  name: string;
  phone: string;
  entityName: string;
  flexPlayerId?: number;
  referralId: number;
}

export enum PrizePlayerStatus {
  won = "won",
  winning = "winning",
  notWinning = "notWinning"
}

interface PrizePlayerStatusDetailsBase {
  prize: FundraisingPrize;
  isTie: boolean;
  status: PrizePlayerStatus;
}

interface PrizePlayerStatusDetailsRafflePrize extends PrizePlayerStatusDetailsBase {
  type: "raffle";
  numTickets: number;
}

interface PrizePlayerStatusDetailsNonRafflePrize extends PrizePlayerStatusDetailsBase {
  type: "nonRaffle";
}

export type PrizePlayerStatusDetails = PrizePlayerStatusDetailsRafflePrize | PrizePlayerStatusDetailsNonRafflePrize;

export interface FundraiserPlayerPrizeInfo {
  totalRaffleTicketsEarned: number;
  totalRaffleTicketsProjected: number;
  prizesCompleted: PrizePlayerStatusDetails[];
  prizesCurrentlyWinning: PrizePlayerStatusDetails[];
  prizesNotWinning: PrizePlayerStatusDetails[];
}

export interface FundraiserFinalPlayerRaffleTotalDetails {
  playerId: PlayerId;
  playerName: string;
  teamName: string;
  numRaffleTicketsEarned: number;
}

export interface FundraiserFinalPlayerRaffleTotalDetailsPlusPrizeWon extends FundraiserFinalPlayerRaffleTotalDetails {
  prizeWon: string;
}

export type FundraisingReminderPreset = "generic" | "email-day" | "text-day" | "social-day";

export interface PlayerFundsRaisedInfo {
  playerAmount: number;
  teamAmount: number;
  clubAmount: number;
  totalAmount: number;
  firstName: string;
  lastName: string;
  teamName: string;
  teamId: TeamId;
}

export interface OrgProgressReportTeamData {
  teamName: string;
  teamAmountRaised: number;
  avgRaisedPerPlayerWhoHasRaised: number;
  numPlayersRaised: number;
  numPlayersOnTeam: number;
  numPlayerWithViews: number;
  numPlayersWithMoreThanTenViews: number;
  numCustomTeamPrizes: number;
}
