import {
  SoccerFormationKeys,
  SoccerHalfMinuteLength,
  SoccerMaxFieldPlayers,
  SoccerOvertimeHalfLengths,
  SoccerPositionNumber,
  SoccerStatModes,
  SoccerPositionNumberWithSubOut
} from "../misc";
import { AccountId } from "./Account.schema";
import { CalendarEntryId, CalendarEntryGameScrimmageEndGameDetails } from "./CalendarEntry.schema";
import { MVPVotingMode, TeamId } from "./Team.schema";
import { PlayerId } from ".";
import * as _ from "lodash";
import { PKShootoutScore } from "./SoccerGameEvent.schema";

export type MvpTypes = "bestOffense" | "bestDefense" | "mvp";
export type SoccerGameId = string;
export type GameStages = "pre-setup" | "setup" | "started" | "ended";

export type QueuedSubProps = {
  queuedPlayerIdToNewPosition: { [playerId in string]?: SoccerPositionNumberWithSubOut };
  queuedPlayerIdToOldPosition: { [playerId in string]?: SoccerPositionNumberWithSubOut };
  queuedSubAccountId?: AccountId;
};

interface BaseSoccerGame {
  id: string;
  createdAtMS: number;
  calendarEntryId: CalendarEntryId;
  teamId: TeamId;
  squad?: "a" | "b" | "c";
  maxFieldPlayers: SoccerMaxFieldPlayers;
  recorderAccountId: AccountId;
  overtimeHalfLengthMinutes?: SoccerOvertimeHalfLengths;
  fieldSide: "left" | "right";
  accountIdsInLiveGameMode?: Record<AccountId, boolean>;
  liveGameModePleaForPhotosHasBeenSent?: boolean;
  areAwardsDisabled?: boolean;
  areMVPsDisbaled?: boolean;
  votingMode: MVPVotingMode;
  clockMode?: "default" | "nfhs"; //Let's you set the clock mode. "nfhs" refers to the national highschool federation. Toggles on various features, such as counting down the clock and stopping the clock automatically after certain events.
}

export interface PreSetupSoccerGame extends BaseSoccerGame {
  gameStage: "pre-setup";
  statMode?: SoccerStatModes;
  roster?: { [playerId in string]?: true };
  starterIdToPosition?: { [playerId in string]?: SoccerPositionNumber };
  startingFormation?: SoccerFormationKeys;
  halfLengthMinutes?: SoccerHalfMinuteLength;
}

export interface SetupSoccerGame extends BaseSoccerGame {
  gameStage: "setup";
  statMode: SoccerStatModes;
  roster?: { [playerId in string]?: true };
  starterIdToPosition: { [playerId in string]?: SoccerPositionNumber };
  startingFormation: SoccerFormationKeys;
  halfLengthMinutes: SoccerHalfMinuteLength;
}

export interface StartedSoccerGame extends BaseSoccerGame {
  gameStage: "started";
  statMode: SoccerStatModes;
  roster: { [playerId in string]?: true };
  starterIdToPosition: { [playerId in string]?: SoccerPositionNumber };
  startingFormation: SoccerFormationKeys;
  halfLengthMinutes: SoccerHalfMinuteLength;
  overtimeHalfLengthMinutes?: number;
  officialStartOfGameMS: number;
  queuedPlayerIdToNewPosition?: { [playerId in string]?: SoccerPositionNumberWithSubOut };
  queuedPlayerIdToOldPosition?: { [playerId in string]?: SoccerPositionNumberWithSubOut };
  queuedSubAccountId?: AccountId;
}

export interface EndedSoccerGame extends BaseSoccerGame {
  gameStage: "ended";
  statMode: SoccerStatModes;
  roster: { [playerId in string]?: true };
  starterIdToPosition: { [playerId in string]?: SoccerPositionNumber };
  startingFormation: SoccerFormationKeys;
  halfLengthMinutes: SoccerHalfMinuteLength;
  overtimeHalfLengthMinutes?: number;
  officialStartOfGameMS: number;
  officialEndOfGameMS: number;
  officiallyEndedGameSummaryStats: CalendarEntryGameScrimmageEndGameDetails;
  queuedPlayerIdToNewPosition?: { [playerId in string]?: SoccerPositionNumberWithSubOut };
  queuedPlayerIdToOldPosition?: { [playerId in string]?: SoccerPositionNumberWithSubOut };
  queuedSubAccountId?: AccountId;
  leaderboardWinners?: { [type in SoccerGameLeaderboardTypes]?: PlayerId };
  votes?: Record<PlayerId, Votes>;
  mvpWinners?: { [type in MvpTypes]?: PlayerId };
}

export type Votes = Record<MvpTypes, PlayerId>;

export type PostSetupSoccerGame = SetupSoccerGame | StartedSoccerGame | EndedSoccerGame;

export type PostStartedSoccerGame = StartedSoccerGame | EndedSoccerGame;
export type PreStartedSoccerGame = SetupSoccerGame | PreSetupSoccerGame;

export type SoccerGame = PreSetupSoccerGame | SetupSoccerGame | StartedSoccerGame | EndedSoccerGame;

export function isPostStartedSoccerGame(soccerGame: SoccerGame): soccerGame is PostStartedSoccerGame {
  return soccerGame.gameStage === "ended" || soccerGame.gameStage === "started";
}

type UnionKeys<T> = T extends any ? keyof T : never;
type DistributivePick<T, K extends UnionKeys<T>> = T extends any ? Pick<T, Extract<keyof T, K>> : never;
export function pickStatRelevantGameFields(game: SoccerGame) {
  const relevantKeysMap = {
    calendarEntryId: true,
    gameStage: true,
    halfLengthMinutes: true,
    createdAtMS: true,
    maxFieldPlayers: true,
    officialEndOfGameMS: true,
    officialStartOfGameMS: true,
    officiallyEndedGameSummaryStats: true,
    overtimeHalfLengthMinutes: true,
    recorderAccountId: true,
    starterIdToPosition: true,
    roster: true,
    squad: true,
    statMode: true,
    startingFormation: true,
    teamId: true,
    id: true
  };

  return _.pickBy(game, k => (relevantKeysMap as any)[k as any]) as DistributivePick<SoccerGame, keyof typeof relevantKeysMap>;
}

export enum SoccerGameLeaderboardTypes {
  passEfficiency = "passEfficiency",
  possessionSuccess = "possessionSuccess",
  shotEfficiency = "shotEfficiency",
  crosses = "crosses",
  assists = "assists",
  touches = "touches",
  possessionParticipation = "possessionParticipation",
  recoveries = "recoveries",
  fouls = "fouls",
  goalieSaves = "goalieSaves",
  goalieDistribution = "goalieDistribution",
  subImpact = "subImpact"
}

export const SoccerGameLeaderboardTypesCamelCase: Record<SoccerGameLeaderboardTypes, string> = {
  passEfficiency: "pass_efficiency",
  possessionSuccess: "possession_success",
  shotEfficiency: "shot_efficiency",
  crosses: "crosses",
  assists: "assists",
  touches: "touches",
  possessionParticipation: "possession_participation",
  recoveries: "recoveries",
  fouls: "fouls",
  goalieSaves: "goalie_saves",
  goalieDistribution: "goalie_distribution",
  subImpact: "sub_impact"
};
