import { GENDERS, TEAM_TYPES, TEAM_PERMISSIONS, TEAM_SPORT, GAME_VENUES } from "../constants";

import { OrgId } from "./Org.schema";
import { AccountId } from "./Account.schema";
import { PlayerId } from "./Player.schema";
import { LicenseId } from "./License.schema";
import { SchoolId } from "./School.schema";
import { OrgRegistrationPackageId, OrgTeamTagId, PlayerBundleId } from "..";

export type TeamId = string;

export enum TeamFeatures {
  squads = "squads",
  viewIndividualStatsForEveryone = "viewIndividualStatsForEveryone",
  mvps = "mvps",
  awards = "awards",
  stats = "stats"
}

export enum MVPVotingMode {
  standard = "standard",
  all = "all",
  none = "none"
}

export interface ChecksPayableInfo {
  recipient: string;
  address1: string;
  address2?: string;
  city: string;
  province: string;
  regionCode: string;
  country: string;
}

export interface Team__Shell {
  name: string;
  shortName: string;
  orgId?: OrgId;
  country: string;
  teamType: TEAM_TYPES;
  teamSport: TEAM_SPORT;
  province?: string;
  regionCode?: string;
  birthYear?: string;
  gender: GENDERS;
  timezone: string;
  disableCovid19Confirm?: boolean;
  enabledFeatures?: { [feature in TeamFeatures]?: true };
  logoUri?: string;
  schoolId?: SchoolId;
  defaultJerseyColors?: { [key in GAME_VENUES]?: string };
  teamPhotoUri?: string;
  squads?: Team["squads"];
  checksPayableInfo?: ChecksPayableInfo;
  votingMode?: MVPVotingMode;
}

export interface Team {
  id: TeamId;
  legacyId?: string;
  createdAtMS: number;
  deletedAtMS: number;
  name: string;
  shortName: string;
  orgId?: OrgId;
  country: string;
  teamType: TEAM_TYPES;
  teamSport: TEAM_SPORT;
  province?: string;
  regionCode?: string;
  teamPhotoUri?: string;
  squads?: {
    a: { name: string; short: string };
    b: { name: string; short: string };
    c?: { name: string; short: string };
  };
  squadsPlayerMapping?: Record<PlayerId, { a?: true; b?: true; c?: true }>;
  enabledFeatures?: { [feature in TeamFeatures]?: true };
  derived: {
    activePlayerIds: Record<PlayerId, true>;
    activePlayerBundleIds: Record<PlayerBundleId, true>;
  };
  linkedLicenseId?: LicenseId;
  birthYear?: string;
  gender: GENDERS;
  timezone: string;
  disableCovid19Confirm?: boolean;
  accounts: Record<AccountId, Team__Account | undefined>;
  hasOnboarded?: true;
  lastLicenseExpirationWarningSentMS?: number;
  lastLicenseExpirationSentMS?: number;
  logoUri?: string;
  hideTeamSetupChecklist?: true;
  schoolId?: SchoolId;
  lastTimeMajorGameUpdateWasMadeMS?: number;
  defaultJerseyColors?: { [key in GAME_VENUES]?: string };
  checksPayableInfo?: ChecksPayableInfo;
  fundraiserRemindersSent?: Record<number, { sentAtMS: number; senderName: string }>; // key is FlexTeamId
  assignedOrgTagIds?: Record<OrgTeamTagId, true>;
  votingMode?: MVPVotingMode;
}

export interface Team__Account {
  exists: true;
  staffTitle?: Team__StaffTypes;
  customStaffTitle?: string;
  roles: {
    staff?: boolean;
    athlete?: boolean;
    guardian?: boolean;
  };
  additionalPermissions?: {
    [TEAM_PERMISSIONS.manageEvents]?: true;
    [TEAM_PERMISSIONS.manageRoster]?: true;
    [TEAM_PERMISSIONS.recordStats]?: true;
    [TEAM_PERMISSIONS.viewIndividualStats]?: true;
    [TEAM_PERMISSIONS.manageRolesAndPermissions]?: true;
    [TEAM_PERMISSIONS.squad_a_staff]?: true;
    [TEAM_PERMISSIONS.squad_b_staff]?: true;
    [TEAM_PERMISSIONS.squad_c_staff]?: true;
  };
}

export enum Team__StaffTypes {
  headCoach = "headCoach",
  assistantCoach = "assistantCoach",
  teamAdmin = "teamAdmin",
  staffMember = "staffMember"
}
export enum Team__NonStaffTypes {
  guardian = "guardian",
  athlete = "athlete"
}

export interface WarehouseTeamDerived {
  createdByAccountId?: string | "UNKNOWN";
  isNewbie?: boolean; // Created by a user that has an account less than 7 days old at the time
}
