import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const AmericanFootballBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.americanFootball as const
};

const AmericanFootballBaseWithDownObj = {
  ...AmericanFootballBaseObj,
  possessor: "" as "own-team" | "opponent-team",
  startingYardLine: 0,
  startingDown: 1 as 1 | 2 | 3 | 4,
  startingYardsToFirst: 0
} as const;

const FinalYardLineObj = {
  finalYardLine: 0, //From 0 to 1
  touchdown: "own-team" as "own-team" | "opponent-team" | undefined
};

const AmericanFootballBaseWithDownAndFinalObj = {
  ...AmericanFootballBaseWithDownObj,
  ...FinalYardLineObj
};

const StartQuarterBaseObj = {
  ...AmericanFootballBaseObj,
  type: "startQuarter" as const
};

type StartQuarterBaseType = typeof StartQuarterBaseObj;

type StartQuarterType =
  | (StartQuarterBaseType & { startsGame: true; fieldLengthYards: number })
  | (StartQuarterBaseType & { startsGame: false });

export const SKE_americanFootballObj = {
  SKE_americanFootballStartQuarter: StartQuarterBaseObj as StartQuarterType,
  SKE_americanFootballEndQuarter: {
    ...AmericanFootballBaseObj,
    type: "endQuarter" as const,
    endsGame: true as true | undefined
  },
  SKE_americanFootballKickoff: {
    ...AmericanFootballBaseObj,
    ...FinalYardLineObj,
    type: "kickoff" as const,
    landingYardLine: 0 as number | undefined,
    kickerId: "" as ScoreKeepingPlayerId,
    receiverId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballPenalty: {
    ...AmericanFootballBaseWithDownObj,
    type: "penalty" as const,
    finalYardLine: 0,
    infractingPlayerId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballManualPlay: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "manualPlay" as const,
    description: ""
  },
  SKE_americanFootballSack: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "sack" as const,
    tacklerId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballSafety: {
    ...AmericanFootballBaseWithDownObj,
    type: "safety" as const
  },
  SKE_americanFootballIncompletePass: {
    ...AmericanFootballBaseWithDownObj,
    type: "incompletePass" as const,
    throwerId: "" as ScoreKeepingPlayerId,
    intendedRecipientId: "" as undefined | ScoreKeepingPlayerId
  },
  SKE_americanFootballPostTDExtraPoint: {
    ...AmericanFootballBaseObj,
    type: "postTDExtraPoint" as const,
    kickerId: "" as ScoreKeepingPlayerId,
    result: "" as "miss" | "block" | "block-and-2-pt-return" | "success"
  },
  SKE_americanFootballPostTD2PtConversion: {
    ...AmericanFootballBaseObj,
    type: "postTD2PtConversion" as const,
    result: "" as "failure" | "success" | "fumble-and-2-pt-return"
  },
  SKE_americanFootballPass: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "passPlay" as const,
    throwerId: "" as ScoreKeepingPlayerId,
    recipientId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballInterception: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "interception" as const,
    throwerId: "" as ScoreKeepingPlayerId,
    interceptorId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballFumble: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "fumble" as const,
    fumblerId: "" as ScoreKeepingPlayerId,
    recovererId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballRun: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "run" as const,
    runnerId: "" as ScoreKeepingPlayerId,
    tacklerId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballPunt: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "punt" as const,
    kickerId: "" as ScoreKeepingPlayerId,
    landingYardLine: 0 as number | undefined,
    receiverId: "" as undefined | ScoreKeepingPlayerId
  },
  //Is this event necessary? Theoretically wouldn't just the finalYardLine be different?
  SKE_americanFootballPuntBlocked: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "puntBlocked" as const,
    kickerId: "" as ScoreKeepingPlayerId
  },
  SKE_americanFootballFieldGoal: {
    ...AmericanFootballBaseWithDownObj,
    type: "fieldGoal" as const,
    kickYardLine: 0,
    kickerId: "" as ScoreKeepingPlayerId
  },
  //TODO: Do we need an interception? It's pretty much the same as a block except the finalYardLine will be different than predicted...
  //And if we don't need interception, can we just remove the result property and just use blockerId?
  SKE_americanFootballFieldGoalMiss: {
    ...AmericanFootballBaseWithDownAndFinalObj,
    type: "fieldGoalMiss" as const,
    kickerId: "" as ScoreKeepingPlayerId,
    kickYardLine: 0,
    blockerId: "" as undefined | ScoreKeepingPlayerId,
    result: "" as "miss" | "block" | "interception"
  }
} as const;

export type SKE_americanFootball = Values<NullableToOptional<typeof SKE_americanFootballObj>>;
