import { OrgId } from "./Org.schema";

export enum OrgPaymentPlanType {
  evenlySplit = "evenlySplit",
  customPercent = "customPercent",
  monthly = "monthly"
}

export enum OrgPaymentPlanInitialPaymentType {
  fixedAmount = "fixedAmount",
  percent = "percent",
  firstInstallment = "firstInstallment"
}

export type OrgPaymentPlanId = string;
type OrgPaymentPlanBase = {
  id: OrgPaymentPlanId;
  orgId: OrgId;
  name: string;
  createdAtMS: number;
  lateFeeAmountCents?: number;
  numberOfDaysUntilLate: number;
  allowUsersToOverrideChargeDateUpToLateFeeDeadline?: boolean;
  initialPaymentSettings?:
    | { type: OrgPaymentPlanInitialPaymentType.fixedAmount; ammountCents: number }
    | { type: OrgPaymentPlanInitialPaymentType.percent; percent: number }
    | { type: OrgPaymentPlanInitialPaymentType.firstInstallment };
  setupFeeAmountCents?: number;
  installmentFeeAmountCents?: number;
};

export enum DownPaymentType {
  amountOff = "amountOff",
  percentOff = "percentOff"
}

export type OrgPaymentPlanEven = {
  type: OrgPaymentPlanType.evenlySplit;
  chargeDatesWithoutYear: {
    date: string; // MM-DD
  }[];
} & OrgPaymentPlanBase;

export type OrgPaymentPlanPercent = {
  type: OrgPaymentPlanType.customPercent;
  chargeDatesWithoutYear: {
    date: string; // MM-DD
    percent: number; //integer from 1 to 100. All values must sum to 100
  }[];
} & OrgPaymentPlanBase;

export type OrgPaymentPlanMonthly = {
  type: OrgPaymentPlanType.monthly;
  numberPeriods: number;
  dayOfTheMonth?: number;
} & OrgPaymentPlanBase;

export type OrgPaymentPlan = OrgPaymentPlanEven | OrgPaymentPlanPercent | OrgPaymentPlanMonthly;

// This is just used in compute and in the fronted
export interface PaymentPlanSinglePaymentInfo {
  amountCents: number;
  originalPaymentDateMS: number;
  feeAmountCents: number;
  dueDateMS: number;
  manualDateMS?: number;
}
