import {
  Team,
  CalendarEntry,
  CalendarEntryGameScrimmage,
  Player,
  SoccerGame,
  PreSetupSoccerGame,
  PrettyPlayer,
  StartedSoccerGame,
  PrettyPlayerBundle,
  MVPVotingMode
} from "../collections";
import { GENDERS, TEAM_TYPES, CALENDAR_ENTRY_TYPES, GAME_VENUES, TEAM_SPORT } from "../constants";
import * as moment from "moment";

export enum SoccerIds {
  opponentTeamId = "opponent-team",
  ownTeamGenericPlayer = "own-team-generic",
  opponentFieldPlayer = "opponent-team-field-player",
  opponentGoalie = "opponent-team-goalie",
  opponentGenericPlayer = "opponent-team-generic-player"
}

export const exampleTeamId = "example-season-stats-team-id";
export const exampleTeamIdWithSquad = "example-season-stats-team-id-NONE";
export const examplePlayerBundleId = "example-player-bundle-id";

export const SUB_OUT_MAGIC_CONSTANT = "SUB_OUT_MAGIC_CONSTANT";

export type SoccerPositionNumberWithSubOut = SoccerPositionNumber | "SUB_OUT_MAGIC_CONSTANT";

export enum SoccerPositionNumber {
  "n1" = "1",
  "n2" = "2",
  "n3" = "3",
  "n4" = "4",
  "n5" = "5",
  "n6" = "6",
  "n7" = "7",
  "n8" = "8",
  "n9" = "9",
  "n10" = "10",
  "n11" = "11"
}

export enum SoccerMaxFieldPlayers {
  "n11" = 11,
  "n10" = 10,
  "n9" = 9,
  "n8" = 8,
  "n7" = 7,
  "n6" = 6,
  "n5" = 5,
  "n4" = 4,
  "n3" = 3
}

export enum SoccerHalfNumber {
  "n1" = 1,
  "n2" = 2,
  "n3" = 3,
  "n4" = 4
}

export enum SoccerHalfMinuteLength {
  "n20" = 20,
  "n25" = 25,
  "n30" = 30,
  "n35" = 35,
  "n40" = 40,
  "n45" = 45
}

export enum SoccerOvertimeHalfLengths {
  "n5" = 5,
  "n10" = 10,
  "n15" = 15
}

export enum SoccerStatModes {
  team = "team",
  individual = "individual",
  expert = "expert"
}

export enum SoccerShotTargetArea {
  out = "out",
  woodwork = "woodwork",
  inside = "inside",
  unknown = "unknown"
}

type SoccerStatModeInfo = {
  statModeType: string;
  isTeamMode: boolean;
  isIndividualMode: boolean;
  isSwipeMode: boolean;
};

export const SoccerStatModesInfo: Record<SoccerStatModes, SoccerStatModeInfo> = {
  [SoccerStatModes.team]: {
    statModeType: SoccerStatModes.team,
    isTeamMode: true,
    isIndividualMode: false,
    isSwipeMode: false
  },
  [SoccerStatModes.individual]: {
    statModeType: SoccerStatModes.individual,
    isTeamMode: false,
    isIndividualMode: true,
    isSwipeMode: false
  },
  [SoccerStatModes.expert]: {
    statModeType: SoccerStatModes.expert,
    isTeamMode: false,
    isIndividualMode: true,
    isSwipeMode: true
  }
};

export enum SoccerPositionTypes {
  forward = "forward",
  midfielder = "midfielder",
  defender = "defender",
  goalkeeper = "goalkeeper"
}

export const sandboxTeamId = "sandbox-team-id";
export const sandboxColoredJerseyTeamId = "sandbox-colored-jersey-team-id";
export const sandboxCalendarEntryId = "sandbox-calendarEntry";
export const exampleStatDataCalendarEntryId = "example-stat-data-calendarEntry";
export const sandboxSoccerGameId = "sandbox-soccer-game";

export enum SANDBOX_PLAYER_IDS {
  n0 = "sandbox-player-0",
  n1 = "sandbox-player-1",
  n2 = "sandbox-player-2",
  n3 = "sandbox-player-3",
  n4 = "sandbox-player-4",
  n5 = "sandbox-player-5",
  n6 = "sandbox-player-6",
  n7 = "sandbox-player-7",
  n8 = "sandbox-player-8",
  n9 = "sandbox-player-9",
  n10 = "sandbox-player-10",
  n11 = "sandbox-player-11",
  n12 = "sandbox-player-12",
  n13 = "sandbox-player-13",
  n14 = "sandbox-player-14"
}

export const SANDBOX_PLAYERS_INFO: PrettyPlayer[] = [
  [SANDBOX_PLAYER_IDS.n0, "Elijah", "Bradley", "1"],
  [SANDBOX_PLAYER_IDS.n1, "Jose", "Ruiz", "4"],
  [SANDBOX_PLAYER_IDS.n2, "Evan", "Teichert", "6"],
  [SANDBOX_PLAYER_IDS.n3, "Logan", "Sanders", "7"],
  [SANDBOX_PLAYER_IDS.n4, "Payden", "Ashworth", "9"],
  [SANDBOX_PLAYER_IDS.n5, "Sandro", "Torres", "11"],
  [SANDBOX_PLAYER_IDS.n6, "Jax", "Manning", "15"],
  [SANDBOX_PLAYER_IDS.n7, "Nick", "Barros", "17"],
  [SANDBOX_PLAYER_IDS.n8, "Aiden", "Speirs", "19"],
  [SANDBOX_PLAYER_IDS.n9, "Andrew", "Battea", "24"],
  [SANDBOX_PLAYER_IDS.n10, "Tomas", "Lockman", "28"],
  [SANDBOX_PLAYER_IDS.n11, "Gabriel", "Warren", "32"],
  [SANDBOX_PLAYER_IDS.n12, "Carlos", "Morgan", "55"],
  [SANDBOX_PLAYER_IDS.n13, "Ian", "Fitz-Ashton", "68"],
  [SANDBOX_PLAYER_IDS.n14, "Lucas", "Zopetti", "98"]
].map(mapPlayerInfoArray.bind(null, sandboxTeamId));

export const COLORED_JERSEY_PLAYERS_COLORS = {
  red: "red",
  pink: "pink",
  yellow: "yellow",
  blue: "blue",
  green: "green",
  purple: "purple",
  white: "#eeeeee",
  orange: "orange"
};

export function mapPlayerInfoArray(teamId: string, info: string[], index: number): PrettyPlayer {
  const player: Player = {
    id: info[0],
    createdAtMS: Date.now(),
    teamId: teamId,
    jerseyNumber: info[3],
    deletedAtMS: 0,
    virtualAthleteAccount: {
      firstName: info[1],
      lastName: info[2],
      email: "",
      profileImageUri: `https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fdemo_files%2Fboys-large-${index}.png?alt=media`,
      profileImageUriSmall: `https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fdemo_files%2Fboys-small-${index}.png?alt=media`
    }
  };
  return {
    player,
    derived: {
      accountInfo: {
        ...player.virtualAthleteAccount
      },
      accountInfoSource: "player"
    }
  };
}

export function isHardcodedCalendarEntryId(calendarEntryId: string) {
  return calendarEntryId === sandboxCalendarEntryId || calendarEntryId === exampleStatDataCalendarEntryId;
}

export function isHardcodedSoccerGame(game: SoccerGame) {
  return game.id === sandboxSoccerGameId || isHardcodedCalendarEntryId(game.calendarEntryId);
}

export const SANDBOX_SOCCER_GAME: () => PreSetupSoccerGame = () => ({
  calendarEntryId: sandboxCalendarEntryId,
  id: sandboxSoccerGameId,
  createdAtMS: Date.now(),
  teamId: sandboxTeamId,
  recorderAccountId: "",
  maxFieldPlayers: SoccerMaxFieldPlayers.n11,
  //Set sane defaults for when hacking in dev mode.
  gameStage: "pre-setup",
  votingMode: MVPVotingMode.none,
  statMode: isDevelopmentEnv() ? SoccerStatModes.individual : undefined,
  halfLengthMinutes: isDevelopmentEnv() ? 40 : undefined,
  startingFormation: isDevelopmentEnv() ? ("1-4-3-3" as any) : undefined,
  starterIdToPosition: {
    [SANDBOX_PLAYER_IDS.n1]: SoccerPositionNumber.n1,
    [SANDBOX_PLAYER_IDS.n2]: SoccerPositionNumber.n2,
    [SANDBOX_PLAYER_IDS.n3]: SoccerPositionNumber.n3,
    [SANDBOX_PLAYER_IDS.n4]: SoccerPositionNumber.n4,
    [SANDBOX_PLAYER_IDS.n5]: SoccerPositionNumber.n5,
    [SANDBOX_PLAYER_IDS.n6]: SoccerPositionNumber.n6,
    [SANDBOX_PLAYER_IDS.n7]: SoccerPositionNumber.n7,
    [SANDBOX_PLAYER_IDS.n8]: SoccerPositionNumber.n8,
    [SANDBOX_PLAYER_IDS.n9]: SoccerPositionNumber.n9,
    [SANDBOX_PLAYER_IDS.n10]: SoccerPositionNumber.n10,
    [SANDBOX_PLAYER_IDS.n11]: SoccerPositionNumber.n11
  },
  fieldSide: "left"
});

export const SANDBOX_STARTED_SOCCER_GAME: () => StartedSoccerGame = () => ({
  calendarEntryId: sandboxCalendarEntryId,
  id: sandboxSoccerGameId,
  createdAtMS: Date.now(),
  teamId: sandboxTeamId,
  recorderAccountId: "",
  votingMode: MVPVotingMode.none,
  maxFieldPlayers: SoccerMaxFieldPlayers.n11,
  //Set sane defaults for when hacking in dev mode.
  gameStage: "started",
  statMode: SoccerStatModes.team,
  halfLengthMinutes: 40,
  startingFormation: "1-4-3-3" as any,
  roster: {
    "sandbox-player-0": true,
    "sandbox-player-1": true,
    "sandbox-player-10": true,
    "sandbox-player-11": true,
    "sandbox-player-12": true,
    "sandbox-player-13": true,
    "sandbox-player-14": true,
    "sandbox-player-2": true,
    "sandbox-player-3": true,
    "sandbox-player-4": true,
    "sandbox-player-5": true,
    "sandbox-player-6": true,
    "sandbox-player-7": true,
    "sandbox-player-8": true,
    "sandbox-player-9": true
  },
  officialStartOfGameMS: Date.now() - 1000,
  starterIdToPosition: {
    [SANDBOX_PLAYER_IDS.n1]: SoccerPositionNumber.n1,
    [SANDBOX_PLAYER_IDS.n2]: SoccerPositionNumber.n2,
    [SANDBOX_PLAYER_IDS.n3]: SoccerPositionNumber.n3,
    [SANDBOX_PLAYER_IDS.n4]: SoccerPositionNumber.n4,
    [SANDBOX_PLAYER_IDS.n5]: SoccerPositionNumber.n5,
    [SANDBOX_PLAYER_IDS.n6]: SoccerPositionNumber.n6,
    [SANDBOX_PLAYER_IDS.n7]: SoccerPositionNumber.n7,
    [SANDBOX_PLAYER_IDS.n8]: SoccerPositionNumber.n8,
    [SANDBOX_PLAYER_IDS.n9]: SoccerPositionNumber.n9,
    [SANDBOX_PLAYER_IDS.n10]: SoccerPositionNumber.n10,
    [SANDBOX_PLAYER_IDS.n11]: SoccerPositionNumber.n11
  },
  fieldSide: "left"
});

export function isDevelopmentEnv(): boolean {
  const tempGlobal: any = global || {};
  return tempGlobal.__DEV__ || global?.process?.env?.NODE_ENV === "development";
}
