import { LICENSE_TYPES, RevenueCatEntitlements } from "../constants";
import { TeamId } from "./Team.schema";
import { OrgId } from "./Org.schema";
import { AccountId } from "./Account.schema";

export type LicenseId = string;
interface LicenseRoot {
  id: LicenseId;
  status: "active" | "inactive"; // Definitive attribute that decides if the license is active. It derived and updated based on other data though.
  createdAtMS: number;
  startAtMS: number;
  expireAtMS: number; //IMPORTANT NOTE: On revenue cat licenses, this value is wrong. It should be fetched dynamically from RevenueCat.
}
// -------
// ACCOUNT
// -------

export interface License__AccountManual extends LicenseRoot {
  type: LICENSE_TYPES.account;
  accountLicenseType: "manual";
  accountId: AccountId;
  isTrial: boolean; // Really just used for display purposes
}

export interface License__AccountInAppRevenueCat extends LicenseRoot {
  type: LICENSE_TYPES.account;
  accountLicenseType: "in-app-revenue-cat";
  accountId: AccountId;
  derivedExternal?: {
    activeRevenueCatEntitlements: { [e in RevenueCatEntitlements]?: true };
  };
}

// -------
// TEAM
// -------

export interface License__TeamManual extends LicenseRoot {
  type: LICENSE_TYPES.team;
  teamLicenseType: "manual";
  teamId: TeamId;
  isTrial: boolean; // Really just used for display purposes
}

// This should have Payments that point to this
export interface License__TeamStripe extends LicenseRoot {
  type: LICENSE_TYPES.team;
  teamLicenseType: "stripe";
  teamId: TeamId;
}

// -------
// ORG
// -------

// Any payments should be tracked in the notes
export interface License__OrgManual extends LicenseRoot {
  type: LICENSE_TYPES.org;
  orgId: OrgId;
  orgLicenseType: "manual";
}

export type License =
  | License__AccountManual
  | License__AccountInAppRevenueCat
  | License__TeamManual
  | License__TeamStripe
  | License__OrgManual;

/**
 * Helper function which flattens all the various license types into a flat type safe list for easier switch statements in the UI, etc
 */
export function assignLicenseToPlanType(license: License | null | undefined): AssignLicensePlanTypeReturn {
  if (!license) {
    return {
      licensePlanType: LICENSE_PLAN_TYPES.free,
      license: null
    };
  } else if (license.type === LICENSE_TYPES.team) {
    if (license.teamLicenseType === "stripe") {
      return {
        licensePlanType: LICENSE_PLAN_TYPES.teamStripe,
        license
      };
    } else {
      if (license.isTrial) {
        return {
          licensePlanType: LICENSE_PLAN_TYPES.teamTrial,
          license
        };
      } else {
        return {
          licensePlanType: LICENSE_PLAN_TYPES.teamGift,
          license
        };
      }
    }
  } else if (license.type === LICENSE_TYPES.org) {
    return {
      licensePlanType: LICENSE_PLAN_TYPES.orgManual,
      license
    };
  } else {
    if (license.accountLicenseType === "in-app-revenue-cat") {
      if (license.derivedExternal?.activeRevenueCatEntitlements["premium-family-01"]) {
        return {
          licensePlanType: LICENSE_PLAN_TYPES.family,
          license
        };
      } else {
        return {
          licensePlanType: LICENSE_PLAN_TYPES.individual,
          license
        };
      }
    } else {
      if (license.isTrial) {
        return {
          licensePlanType: LICENSE_PLAN_TYPES.individualTrial,
          license
        };
      } else {
        return {
          licensePlanType: LICENSE_PLAN_TYPES.individualGift,
          license
        };
      }
    }
  }
}

export enum LICENSE_PLAN_TYPES {
  free = "free",
  individual = "individual",
  family = "family",
  individualTrial = "individualTrial",
  individualGift = "individualGift",
  teamStripe = "teamStripe",
  teamTrial = "teamTrial",
  teamGift = "teamGift",
  orgManual = "orgManual"
}

type AssignLicensePlanTypeReturn =
  | {
      license: null;
      licensePlanType: LICENSE_PLAN_TYPES.free;
    }
  | {
      license: License__AccountManual;
      licensePlanType: LICENSE_PLAN_TYPES.individualTrial | LICENSE_PLAN_TYPES.individualGift;
    }
  | {
      license: License__AccountInAppRevenueCat;
      licensePlanType: LICENSE_PLAN_TYPES.individual | LICENSE_PLAN_TYPES.family;
    }
  | {
      license: License__TeamStripe;
      licensePlanType: LICENSE_PLAN_TYPES.teamStripe;
    }
  | {
      license: License__TeamManual;
      licensePlanType: LICENSE_PLAN_TYPES.teamGift | LICENSE_PLAN_TYPES.teamTrial;
    }
  | {
      license: License__OrgManual;
      licensePlanType: LICENSE_PLAN_TYPES.orgManual;
    };
