import { ACHIEVEMENTS } from "../constants";

export type AccountId = string;

export interface AccountInfo {
  firstName: string;
  lastName: string;
  suffix?: string;
  email: string;
  phoneNumber?: string;
  profileImageUri?: string;
  profileImageUriSmall?: string;
}

export interface Account extends AccountInfo {
  id: AccountId;
  createdAtMS: number;
  deletedAtMS: number;
  achievements?: {
    [ACHIEVEMENTS.statsTrained]?: true;
    [ACHIEVEMENTS.visitedStatsTab]?: true;
    [ACHIEVEMENTS.viewedAddPhotoVideoModalInLGM]?: true;
  };
}

export interface WarehouseAccountDerived {
  isActive?: boolean;
}
