import { ConversationId } from "./Conversation.schema";
import { AccountId } from "./Account.schema";
import {
  AUTH_TYPES,
  GENDERS,
  CALENDAR_ENTRY_TYPES,
  USER_INTERACTIONS,
  MATCH_TYPES,
  EVENT_REMINDER_NOTIFICATION_TYPES,
  EVENT_TYPE_REMINDER_INTERVAL_HOURS
} from "../constants";
import { PlayerBundleId, PlayerBundle__AccountType } from "./PlayerBundle.schema";
import { Player, PlayerId } from "./Player.schema";
import { LicenseId } from "./License.schema";
import { OrgId } from "./Org.schema";
import { TeamId } from "./Team.schema";
import { PlayerStatKeys, TeamStatKeys } from "../misc";
import { OrgTeamTagId } from "..";

export const AccountPrivateCollectionName = "accountPrivate";

export enum NotificationSettings {
  calendarEvents = "calendarEvents",
  teamAndOrgChannels = "teamAndOrgChannels",
  statsAndGame = "statsAndGame",
  awards = "awards"
}

export interface EventFilterSettings {
  orgIdActivated?: OrgId;
  eventGenders?: Record<GENDERS, true>;
  eventTeamIds?: Record<string, true | undefined>;
  eventBirthYears?: Record<string, true | undefined>;
  eventTypes?: Record<CALENDAR_ENTRY_TYPES, true>;
  squadTypes?: {
    a?: true;
    b?: true;
    c?: true;
    all?: true;
  };
  teamOrgTagIds?: Record<OrgTeamTagId, true>;
}

export interface EventReminderSettings {
  notificationType: EVENT_REMINDER_NOTIFICATION_TYPES;
  eventTypeIntervalHours: { [key in CALENDAR_ENTRY_TYPES]: EVENT_TYPE_REMINDER_INTERVAL_HOURS };
}

export const EventReminderSettings__Default: EventReminderSettings = {
  notificationType: EVENT_REMINDER_NOTIFICATION_TYPES.off,
  eventTypeIntervalHours: {
    game: 0,
    scrimmage: 0,
    practice: 0,
    other: 0
  }
};

export interface TeamIdWithSquadProfileFilterSettings {
  matchType?: { [key in MATCH_TYPES]?: true };
  startDateMS?: number;
  endDateMS?: number;
}

export interface PlayerBundleProfileFilterSettings {
  matchType?: { [key in MATCH_TYPES]?: true };
  startDateMS?: number;
  endDateMS?: number;
  teamIdsWithSquad?: Record<string, true>;
}

export type TeamColorSettings = Record<TeamId, string>;
export type EventColorSettings = Record<CALENDAR_ENTRY_TYPES, string>;
export type EventColorSetting = "team" | "event";

export interface AgreementInfo {
  date: number;
}

export type AgreementType = "terms" | "age";
export interface AccountPrivate {
  id: AccountId;
  createdAtMS: number;
  authType: AUTH_TYPES;
  derived?: {
    activeOrgIds?: Record<string, true>;
  };
  settings?: {
    isTournamentDemoStatTaker?: boolean;
    conversations?: Record<
      ConversationId,
      { muted?: boolean; archivedMS?: number; pinned?: boolean; mutedAccountIds?: Record<string, true> } | undefined
    >;
    notifications: Record<NotificationSettings, boolean>;
    eventFilters?: EventFilterSettings;
    teamColors?: TeamColorSettings;
    eventColors?: EventColorSettings;
    eventColorSetting?: EventColorSetting;
    teamTrendsStats?: Record<TeamId, { [key in TeamStatKeys]?: true }>;
    playerTrendsStats?: Record<PlayerId, { [key in PlayerStatKeys]?: true }>;
    playerBundleTrendsStats?: Record<PlayerBundleId, { [key in PlayerStatKeys]?: true }>;
    teamIdWithSquadProfileFilterSettings?: Record<string, TeamIdWithSquadProfileFilterSettings>;
    playerBundleProfileFilterSettings?: Record<PlayerBundleId, PlayerBundleProfileFilterSettings>;
    eventReminderSettings: EventReminderSettings;
    //participatingConversationUpdatedAtMS is deprecated in favor of mostRecentParticipatingConversationUpdatedId. Delete field after general rollout of September 2023 release
    participatingConversationUpdatedAtMS?: number;
    registrationAndInvoicesLastKeyActionMS?: number;
    mostRecentParticipatingConversationUpdatedId?: string;
  };
  timezone?: string; //For now set on app load. Later on, should make it configurable
  linkedLicenseId?: LicenseId; // A license owned by a particular person
  licenseFamilyShare?: {
    // If they have family license they can specify who they are sharing the license with here
    familySizeLimit?: number; // Defaults to something but allow override so we can support bigger families if needed
    accountIds: Record<AccountId, true>;
  };
  agreements?: Record<AgreementType, AgreementInfo>;
  userInteractions?: {
    [USER_INTERACTIONS.hasUsedAccountTrial]?: boolean;
    [USER_INTERACTIONS.firstLiveGameModeVisit]?: number;
    [USER_INTERACTIONS.firstTimeVoting]?: number;
    [USER_INTERACTIONS.lastRatingsPrompt]?: number;
    [USER_INTERACTIONS.numberOfGamesAsHeadCoachSinceWeAddedCoachShareReminder]?: number; // This is only to be used for determining when to remind to share stats with opposing coach.
    [USER_INTERACTIONS.lastSentEmailAboutUpgradingTeam]?: Record<TeamId, number>;
    [USER_INTERACTIONS.hasSeenTrendsInfoModal]?: boolean;
    [USER_INTERACTIONS.numberOfSessions]?: number;
  };
  communicationLocale: string;
}
