export type CouponId = string;

export enum CouponType {
  percentOff = "percentOff",
  amountOff = "amountOff",
  customAmount = "customAmount"
}

export interface CouponBase {
  id: CouponId;
  createdAtMS: number;
  expirationDateMS?: number;
  useLimit?: number;
  numberTimesUsed?: number;
}

export interface CouponPercentOff extends CouponBase {
  type: CouponType.percentOff;
  percentOff: number; // 0 to 1Ok
}

export interface CouponAmountOff extends CouponBase {
  type: CouponType.amountOff;
  amountOff: number;
}

export interface CouponCustomAmount extends CouponBase {
  type: CouponType.customAmount;
  customAmount: number;
}

export type Coupon = CouponPercentOff | CouponAmountOff | CouponCustomAmount;
