import { AccountId, PlayerBundleId, OpenOrgEventId, TeamId, OrgId } from "..";
import { GENDERS } from "../constants";
import { z } from "zod";

export type OpenOrgEventRegistrationId = string;

export interface OpenOrgEventRegistrationNote {
  accountId: AccountId;
  createdAtMS: number;
  text: string;
}

export type TryoutRegistrationActionType =
  | "favorite"
  | "alternateNotificationSent"
  | "invite"
  | "player-accept"
  | "player-reject"
  | "cancel-invite"
  | "manual-player-accept"
  | "rejected-by-coach";

export type TryoutRegistrationAction = {
  type: TryoutRegistrationActionType;
  teamId: TeamId;
  triggeredByAccountId?: AccountId;
  createdAtMS: number;
  actionUrl?: string; //Holds the invite url
  expiresAtMS?: number; //When the invite url expires
};

export interface OpenOrgEventRegistration {
  id: OpenOrgEventRegistrationId;
  type: "tryout" | "camp";
  orgId: OrgId;
  updatedAtMS: number;
  createdAtMS: number;
  openOrgEventId: OpenOrgEventId;
  linkedPlayerId?: string; //This should only be entered AFTER a player has accepted the tryout invite
  paymentInfo?: {
    amountPaid: number;
    couponCodeUsed?: string;
  };
  playerInfo: {
    temporaryIdentifier?: string;
    profileImageUri: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string; // YYYY-MM-DD
    gender: GENDERS;
  };
  guardianContactInfo: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
  tryoutInfo: {
    // This applies to camps too. Not worth migrating to change the name
    sessionSelection: {
      birthYear: string;
      gender: GENDERS;
      orgTeamTagId?: string;
    };
    notes?: OpenOrgEventRegistrationNote[];
    actions?: TryoutRegistrationAction[];
  };
  customSectionResponses?: OpenOrgEventRegistrationAnswer[];
  locale?: string;
}

export enum OpenOrgEventRegistrationQuestionType {
  explainer = "explainer",
  legalDoc = "legal-doc",
  radio = "radio",
  checkbox = "checkbox",
  freeResponse = "free-response"
}

export type OpenOrgEventRegistrationQuestion__Explainer = z.infer<typeof OpenOrgEventRegistrationQuestion__ExplainerZod>;
export const OpenOrgEventRegistrationQuestion__ExplainerZod = z.object({
  id: z.string(),
  sortOrder: z.number(),
  type: z.literal(OpenOrgEventRegistrationQuestionType.explainer),
  shortTitle: z.string(),
  textMD: z.string()
});

export type OpenOrgEventRegistrationQuestion__LegalDoc = z.infer<typeof OpenOrgEventRegistrationQuestion__LegalDocZod>;
export const OpenOrgEventRegistrationQuestion__LegalDocZod = z.object({
  id: z.string(),
  sortOrder: z.number(),
  type: z.literal(OpenOrgEventRegistrationQuestionType.legalDoc),
  shortTitle: z.string(),
  textMD: z.string(),
  isRequired: z.boolean(),
  documentName: z.string()
});

export type OpenOrgEventRegistrationQuestion__Radio = z.infer<typeof OpenOrgEventRegistrationQuestion__RadioZod>;
export const OpenOrgEventRegistrationQuestion__RadioZod = z.object({
  id: z.string(),
  sortOrder: z.number(),
  type: z.literal(OpenOrgEventRegistrationQuestionType.radio),
  shortTitle: z.string(),
  questionMD: z.string(),
  isRequired: z.boolean(),
  options: z.object({ valueMD: z.string() }).array()
});

export type OpenOrgEventRegistrationQuestion__Checkbox = z.infer<typeof OpenOrgEventRegistrationQuestion__CheckboxZod>;
export const OpenOrgEventRegistrationQuestion__CheckboxZod = z.object({
  id: z.string(),
  sortOrder: z.number(),
  type: z.literal(OpenOrgEventRegistrationQuestionType.checkbox),
  shortTitle: z.string(),
  questionMD: z.string(),
  isRequired: z.boolean(),
  options: z.object({ valueMD: z.string() }).array()
});

export type OpenOrgEventRegistrationQuestion__FreeResponse = z.infer<typeof OpenOrgEventRegistrationQuestion__FreeResponseZod>;
export const OpenOrgEventRegistrationQuestion__FreeResponseZod = z.object({
  id: z.string(),
  sortOrder: z.number(),
  type: z.literal(OpenOrgEventRegistrationQuestionType.freeResponse),
  shortTitle: z.string(),
  questionMD: z.string(),
  isRequired: z.boolean()
});

export const OpenOrgEventRegistrationQuestionZod = z.discriminatedUnion("type", [
  OpenOrgEventRegistrationQuestion__ExplainerZod,
  OpenOrgEventRegistrationQuestion__LegalDocZod,
  OpenOrgEventRegistrationQuestion__RadioZod,
  OpenOrgEventRegistrationQuestion__CheckboxZod,
  OpenOrgEventRegistrationQuestion__FreeResponseZod
]);

export type OpenOrgEventRegistrationQuestion =
  | OpenOrgEventRegistrationQuestion__Explainer
  | OpenOrgEventRegistrationQuestion__LegalDoc
  | OpenOrgEventRegistrationQuestion__Radio
  | OpenOrgEventRegistrationQuestion__Checkbox
  | OpenOrgEventRegistrationQuestion__FreeResponse;

export interface OpenOrgEventRegistrationAnswerBase {
  customSectionId: string; //This is actually the OpenOrgEventRegistrationQuestion id
}

export interface OpenOrgEventRegistrationAnswerFreeResponse extends OpenOrgEventRegistrationAnswerBase {
  type: OpenOrgEventRegistrationQuestionType.freeResponse;
  response: string;
}

export interface OpenOrgEventRegistrationAnswerLegalDoc extends OpenOrgEventRegistrationAnswerBase {
  type: OpenOrgEventRegistrationQuestionType.legalDoc;
  textMD?: string;
  agreedToAtMS: number;
}

export interface OpenOrgEventRegistrationAnswerRadio extends OpenOrgEventRegistrationAnswerBase {
  type: OpenOrgEventRegistrationQuestionType.radio;
  selection: string;
}

export interface OpenOrgEventRegistrationAnswerCheckbox extends OpenOrgEventRegistrationAnswerBase {
  type: OpenOrgEventRegistrationQuestionType.checkbox;
  selections: string[];
}

export type OpenOrgEventRegistrationAnswer =
  | OpenOrgEventRegistrationAnswerFreeResponse
  | OpenOrgEventRegistrationAnswerLegalDoc
  | OpenOrgEventRegistrationAnswerRadio
  | OpenOrgEventRegistrationAnswerCheckbox;
