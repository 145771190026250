import { Simplify } from "type-fest";
import { TEAM_SPORT } from "../../../constants";
import { NullableToOptional, TypeEqualsType, Values } from "../../../internal-utils/typescript-utils";
import { ScoreKeepingEventBaseObj, ScoreKeepingPlayerId } from "../types";

const VolleyballBaseObj = {
  ...ScoreKeepingEventBaseObj,
  sport: TEAM_SPORT.volleyball as const
} as const;

const VolleyballBaseWithPlayerObj = {
  ...VolleyballBaseObj,
  playerId: "" as ScoreKeepingPlayerId
} as const;

export const SKE_volleyballObj = {
  SKE_volleyballStartPeriod: {
    ...VolleyballBaseObj,
    type: "startPeriod" as const,
    starters: {} as undefined | Record<string, true>
  },
  SKE_volleyballEndPeriod: {
    ...VolleyballBaseObj,
    type: "endPeriod" as const,
    setWinner: "" as "own-team" | "opponent-team",
    endsGame: true as true | undefined
  },
  SKE_volleyballSubstitute: {
    ...VolleyballBaseObj,
    type: "substitute" as const,
    subbingIn: {} as Record<string, true>,
    subbingOut: {} as Record<string, true>
  },
  SKE_volleyballAce: {
    ...VolleyballBaseWithPlayerObj,
    type: "ace" as const
  },
  SKE_volleyballKill: {
    ...VolleyballBaseWithPlayerObj,
    type: "kill" as const
  },
  SKE_volleyballAssist: {
    ...VolleyballBaseWithPlayerObj,
    type: "assist" as const
  },
  SKE_volleyballServiceError: {
    ...VolleyballBaseWithPlayerObj,
    type: "serviceError" as const
  },
  SKE_volleyballAttackError: {
    ...VolleyballBaseWithPlayerObj,
    type: "serviceError" as const
  },
  SKE_volleyballBlock: {
    ...VolleyballBaseWithPlayerObj,
    type: "block" as const
  },
  SKE_volleyballSideOut: {
    ...VolleyballBaseWithPlayerObj,
    type: "sideOut" as const
  }
} as const;

export type SKE_volleyball = Simplify<Values<NullableToOptional<typeof SKE_volleyballObj>>>;
